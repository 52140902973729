import { DatePicker, DatePickerProps } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { DisciplineAuthorisationCommentValue } from "@/interfaces";
import { Box, Grow, Tooltip } from "@mui/material";
import { convertToDateTimeOffset } from "@/utils";

interface ConfigurableDatePickerProps extends DatePickerProps<Dayjs> {
  configuration: DisciplineAuthorisationCommentValue;
  onUpdate: (updatedValue: DisciplineAuthorisationCommentValue) => void;
}
export function ConfigurableDatePicker({ configuration, onUpdate, ...datePickerProps }: ConfigurableDatePickerProps) {
  const onChangeHandler = (updatedDate: Dayjs | null) =>
    onUpdate({
      ...configuration,
      value: updatedDate && updatedDate.isValid() ? convertToDateTimeOffset(updatedDate) : ""
    });

  return (
    <Tooltip
      TransitionComponent={Grow}
      TransitionProps={{ timeout: 600 }}
      placement={"right-start"}
      title={configuration.authorisationCommentField.helpText}
    >
      <Box sx={{ ml: 4 }}>
        <DatePicker
          value={configuration.value ? dayjs(configuration.value) : null}
          format="DD/MM/YYYY"
          onChange={onChangeHandler}
          {...datePickerProps}
          slotProps={{
            textField: {
              fullWidth: true,
              size: "small",
              inputProps: {
                "data-testid": `auth-comment-config-${configuration.authorisationCommentField.name}-${configuration.id}`
              }
            }
          }}
        />
      </Box>
    </Tooltip>
  );
}
