import { useState } from "react";
import { Box } from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
  GridRowParams,
  GridSortModel
} from "@mui/x-data-grid-pro";
import { generatePath, useNavigate } from "react-router-dom";

import { APPROVAL_REQUEST_PATH, DEFAULT_AR_FILTER_QUERY } from "@/constants";
import { ApprovalRequestStatus, Cycle } from "@/interfaces";
import { formatDate } from "@/utils";
import { useUnreadIndicator, useApprovalRequestList, usePagination } from "@/hooks";
import { ARFilterToolbar, ARFilterQuery } from "@/components/toolbars";

export function PermitReview() {
  const navigate = useNavigate();

  const { currentPage, pageSize, paginationModelChangeHandler } = usePagination();

  const [sortField, setSortField] = useState<string>();
  const [filterQuery, setFilterQuery] = useState<ARFilterQuery>(DEFAULT_AR_FILTER_QUERY);

  const approvalRequestStatusFilter = [ApprovalRequestStatus.DraftPermit];

  const { data: approvalRequestList, isLoading } = useApprovalRequestList({
    approvalRequestStatus: approvalRequestStatusFilter,
    pageNumber: currentPage + 1,
    pageSize,
    sortField,
    hubId: filterQuery.selectedHub,
    projectId: filterQuery.selectedProject,
    siteId: filterQuery.selectedSite
  });

  const { isUnread } = useUnreadIndicator(approvalRequestList.items);

  const dataColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "",
      sortable: false,
      flex: 0.01,
      renderCell: ({ value }: GridRenderCellParams) => (
        <Box
          data-testid={`unread-indicator-${value}`}
          width="0.5rem"
          height="0.5rem"
          display="block"
          borderRadius="100%"
          sx={{ background: "red" }}
          visibility={isUnread(value) ? "visible" : "hidden"}
        />
      )
    },
    {
      field: "referenceNo",
      headerName: "AR Number",
      flex: 0.25
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1
    },
    {
      field: "approvalRequestTypeName",
      headerName: "Type",
      flex: 0.35
    },
    {
      field: "hub",
      headerName: "Hub",
      flex: 0.35
    },
    {
      field: "businessUnitName",
      headerName: "Business Unit",
      flex: 0.35
    },
    {
      field: "cycle",
      headerName: "Cycle",
      valueFormatter: (params) => Cycle[params.value],
      flex: 0.35
    },
    {
      field: "requiredByDate",
      headerName: "Required By",
      valueFormatter: (params) => formatDate(params.value),
      flex: 0.35
    }
  ];

  const handleRowClick: GridEventListener<"rowClick"> = async ({ row: { id } }: GridRowParams) => {
    navigate(generatePath(APPROVAL_REQUEST_PATH.DETAILS, { approvalRequestId: id }));
  };

  const handleSortModelChange = (sortModel: GridSortModel) => {
    const sortClause = sortModel
      .map(({ field, sort }) => {
        return `${field} ${sort}`;
      })
      .join(",");

    setSortField(sortClause);
  };

  return (
    <DataGridPro
      autoHeight={true}
      columns={dataColumns}
      data-testid="permit-review-table"
      density="compact"
      disableColumnMenu={true}
      loading={isLoading}
      onPaginationModelChange={paginationModelChangeHandler}
      onRowClick={handleRowClick}
      onSortModelChange={handleSortModelChange}
      pageSizeOptions={[10, 25, 50, 100]}
      pagination={true}
      paginationMode="server"
      paginationModel={{
        pageSize: approvalRequestList.pageSize,
        page: approvalRequestList.pageNumber - 1
      }}
      rowCount={approvalRequestList.totalRecords ?? 0}
      rows={approvalRequestList.items}
      slots={{ toolbar: ARFilterToolbar }}
      slotProps={{
        toolbar: {
          onFilterChange: (filterQuery: ARFilterQuery) => {
            setFilterQuery(filterQuery);
          },
          approvalRequestStatusFilter
        }
      }}
      sortingMode="server"
      sx={{ border: 0 }}
    />
  );
}
