import { AlertBannerContent } from "@/interfaces";

export const ATTACHMENT_ERRORS = {
  INVALID_FILE_TYPE: "Invalid file type",
  DUPLICATE_FILE: "File already attached",
  DELETE_FAILED: "Failed"
};

export const AR_SUBMIT_ERROR_BANNER: AlertBannerContent = {
  id: "AR_SUBMIT_ERROR_BANNER",
  title: "This request cannot be submitted as it is incomplete.",
  message: "Address the errors in each highlighted section before resubmitting.",
  isPersist: false
};

export const AR_SUBMIT_MAP_ERROR_BANNER: AlertBannerContent = {
  id: "AR_SUBMIT_MAP_ERROR_BANNER",
  title: "This request cannot be submitted as it is incomplete.",
  message: "The request boundary has not been defined, please load shapefiles via the map.",
  isPersist: false
};

export const AR_SUBMIT_MAP_SENSITIVITY_ANALYSED_ERROR_BANNER: AlertBannerContent = {
  id: "AR_SUBMIT_MAP_SENSITIVITY_ANALYSED_ERROR_BANNER",
  title: "This request cannot be submitted as the sensitivity analysis is incomplete",
  message: "Please complete the sensitivity analysis in the map to identify and manage issues before submitting.",
  isPersist: false
};

export const AR_UNEXPECTED_ERROR_BANNER: AlertBannerContent = {
  id: "AR_UNEXPECTED_ERROR_BANNER",
  title: "An unexpected error occurred",
  message: "The action was unsuccessful. If this problem persists then please contact support.",
  isPersist: false
};

export const AR_UPLOAD_IN_PROGRESS_ERROR_BANNER: AlertBannerContent = {
  id: "AR_UPLOAD_IN_PROGRESS_ERROR_BANNER",
  title: "An upload is in progress.",
  message: "Please wait until it is finished before submitting.",
  isPersist: false
};

export const AR_SAVE_ERROR_BANNER: AlertBannerContent = {
  id: "AR_SAVE_ERROR_BANNER",
  title: "This request cannot be saved as it is incomplete.",
  message: "Address the errors in each highlighted section before saving.",
  isPersist: false
};

export const AR_DISTRIBUTED_ERROR_BANNER: AlertBannerContent = {
  id: "AR_DISTRIBUTED_ERROR_BANNER",
  title: "This request cannot be distributed as it is incomplete.",
  message: "Address the errors in each highlighted section before distributing.",
  isPersist: false
};

export const AR_RETURN_ERROR_BANNER: AlertBannerContent = {
  id: "AR_RETURN_ERROR_BANNER",
  title: "This request cannot be returned as it is incomplete.",
  message: "Address the errors in each highlighted section before returning.",
  isPersist: false
};

export const AR_MODIFY_ERROR_BANNER: AlertBannerContent = {
  id: "AR_MODIFY_ERROR_BANNER",
  title: "This request cannot be modified as it is incomplete.",
  message: "Address the errors in each highlighted section before modifying.",
  isPersist: false
};

export const AR_UNDER_AMENDMENT_SME: AlertBannerContent = {
  id: "AR_UNDER_AMENDMENT_SME",
  title: "Currently being edited",
  message: "You can add or edit notes to existing tracking comments only.",
  isPersist: false
};

export const AR_UNDER_AMENDMENT_COORDINATOR: AlertBannerContent = {
  id: "AR_UNDER_AMENDMENT_COORDINATOR",
  title: "Currently being edited",
  message: "You can only view this request while the requestor is making changes.",
  isPersist: true
};

export const AR_RETURN_REQUEST_SME: AlertBannerContent = {
  id: "AR_RETURN_REQUEST_SME",
  title: "Request to return has been submitted",
  message: "You can edit and save the request, however you cannot authorise it.",
  isPersist: false
};

export const mandatorySelectionErrorMessage = (fieldName: string) => `A ${fieldName} must be selected`;

export const AR_DISTRIBUTED_DISCIPLINE_SME_ERROR_BANNER = (
  erroredDisciplines: string,
  hub?: string
): AlertBannerContent => {
  return {
    id: "AR_DISTRIBUTED_DISCIPLINE_SME_ERROR_BANNER",
    title: `The request cannot be distributed as ${erroredDisciplines} SME not defined for ${hub}.`,
    message: "Please contact LAMS administration to address this issue.",
    isPersist: true
  };
};

export const FORM_HELPER_ERROR_CLASS = {
  AR_DETAILS: "ar-details-error"
};

export const ATTACHMENT_MAP_ERRORS = {
  INVALID_ZIP_FILE: "Selected file was not a zip file.",
  INVALID_MISSING_FILE: "Selected file does not contain all required files.",
  INVALID_FILE_CONTENTS: "Selected file contains invalid contents.",
  INVALID_FILE_SIZE: "Selected file exceeds the 5MB requirement.",
  INVALID_FILE: "Selected file is not valid. Please ensure file meets all requirements."
};

export const AR_MAP_UPLOAD_ERROR_BANNER: AlertBannerContent = {
  id: "AR_MAP_UPLOAD_ERROR_BANNER",
  title: "Fail to upload",
  message: "",
  isPersist: false
};
