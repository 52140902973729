import { useState, useEffect, useCallback, useMemo } from "react";
import { createRoot, Root } from "react-dom/client";
import { Grid } from "@mui/material";

import Geometry from "@arcgis/core/geometry/Geometry";
import { union } from "@arcgis/core/geometry/geometryEngine.js";
import Graphic from "@arcgis/core/Graphic";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import { CIMSymbol, SimpleFillSymbol, SimpleLineSymbol } from "@arcgis/core/symbols";
import MapView from "@arcgis/core/views/MapView";
import Expand from "@arcgis/core/widgets/Expand";
import Feature from "@arcgis/core/widgets/Feature";
import LayerList from "@arcgis/core/widgets/LayerList";
import Legend from "@arcgis/core/widgets/Legend";
import Zoom from "@arcgis/core/widgets/Zoom";
import { ArcgisMap } from "@arcgis/map-components-react";
import { defineCustomElements } from "@arcgis/map-components/dist/loader";
import { ArcgisMapCustomEvent } from "@arcgis/map-components/dist/types/components";

import "@arcgis/core/assets/esri/css/main.css";

import { ACCEPTED_MAP_FILE_EXTENSIONS, AR_MAP_UPLOAD_ERROR_BANNER } from "@/constants";
import { AddApprovalRequestMap, SensitivityIssueRecord, SensitivityType } from "@/interfaces";
import { useARContext, useAuthorization } from "@/context";
import {
  useAddApprovalRequestMap,
  useGetApprovalRequestMapDownload,
  usePatchIsMapSensitivityAnalysed,
  useSensitivityRules
} from "@/hooks";
import { drawShapes, executeSensitivityQuery } from "@/utils/mapHelpers";
import { UploadComponent } from "@/components/upload";
import { MapSensitivityOverlay } from "./MapSensitivityOverlay";
import { MapToolbox } from "./MapToolbox";

defineCustomElements(window, { resourcesUrl: "https://js.arcgis.com/map-components/4.30/assets" });

const { REACT_APP_ARCGIS_WEBMAP_ITEM_ID } = import.meta.env;

const BOTTOM_RIGHT_WIDGETS: MapWidgetProps[] = [
  {
    name: "Zoom",
    component: Zoom,
    isExpandable: false,
    componentProps: { layout: "vertical" }
  },
  { name: "Layers", component: LayerList, isExpandable: true },
  { name: "Legends", component: Legend, isExpandable: true }
];

type WidgetComponent = typeof Zoom | typeof LayerList | typeof Legend;

interface MapRenderProps {
  mapLoaded: boolean;
  setMapLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

interface MapWidgetProps {
  name: string;
  component: WidgetComponent;
  componentProps?: Record<string, unknown>;
  isExpandable: boolean;
}

export function MapRender({ mapLoaded, setMapLoaded }: MapRenderProps) {
  const { isRequestor } = useAuthorization();
  const {
    approvalRequest,
    approvalRequestId,
    setUploadInProgress,
    appendAlertBanner,
    removeAlertBanner,
    arMap,
    mapFeatureLayer,
    mapFeatureSetByShapeId
  } = useARContext();

  const { mutate: updateMapMutation } = useAddApprovalRequestMap(approvalRequestId);
  const { mutate: downloadMapFile } = useGetApprovalRequestMapDownload(approvalRequestId);
  const { mutate: patchIsMapSensitivityAnalysed } = usePatchIsMapSensitivityAnalysed(approvalRequestId);
  const sensitivityRules = useSensitivityRules();

  const mapToolboxPanel = useMemo<HTMLDivElement>(() => document.createElement("div"), []);
  const mapToolboxRoot = useMemo<Root>(() => createRoot(mapToolboxPanel), [mapToolboxPanel]);

  const sensitivityPanel = useMemo<HTMLDivElement>(() => document.createElement("div"), []);
  const sensitivityRoot = useMemo<Root>(() => createRoot(sensitivityPanel), [sensitivityPanel]);

  const [fullScreen, setFullScreen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [mapView, setMapView] = useState<MapView>();
  const [mapFileUploading, setMapFileUploading] = useState(false);
  const [mapFileDownloading, setMapFileDownloading] = useState(false);
  const [sensitivityAnalysisLoading, setSensitivityAnalysisLoading] = useState(false);
  const [showSensitivityList, setShowSensitivityList] = useState(false);
  const [sensitivityIssuesList, setSensitivityIssuesList] = useState<SensitivityIssueRecord[]>([]);
  const [viewExtent, setViewExtent] = useState<__esri.Extent | null>(null);

  const arGraphicsLayer = useMemo<GraphicsLayer>(
    () =>
      new GraphicsLayer({
        listMode: "hide"
      }),
    []
  );

  const arIntersectionsLayer = useMemo(
    () =>
      new GraphicsLayer({
        listMode: "hide"
      }),
    []
  );

  const arSymbol = useMemo(
    () =>
      new SimpleLineSymbol({
        color: "yellow",
        width: 2
      }),
    []
  );
  const sensitivitySymbol = useMemo(
    () =>
      new SimpleFillSymbol({
        color: [255, 0, 0, 0.5],
        outline: { width: 0 }
      }),
    []
  );
  const intersectionSymbol = useMemo(
    () =>
      new CIMSymbol({
        data: {
          type: "CIMSymbolReference",
          symbol: {
            type: "CIMLineSymbol",
            symbolLayers: [
              {
                type: "CIMSolidStroke",
                effects: [
                  {
                    type: "CIMGeometricEffectDashes",
                    dashTemplate: [4, 4],
                    lineDashEnding: "HalfPattern",
                    offsetAlongLine: 1
                  }
                ],
                enable: true,
                colorLocked: true,
                capStyle: "Butt",
                joinStyle: "Round",
                miterLimit: 10,
                width: 1,
                color: [255, 255, 255, 255]
              },
              {
                type: "CIMSolidStroke",
                enable: true,
                capStyle: "Butt",
                joinStyle: "Miter",
                miterLimit: 10,
                width: 3.4,
                color: [202, 0, 32, 255]
              }
            ]
          }
        }
      }),
    []
  );

  const runSensitivityAnalysis = useCallback(async () => {
    const mapFeatureSet = await mapFeatureSetByShapeId;

    if (!mapFeatureSet || mapFeatureSet.features.length === 0 || !mapView) {
      return;
    }

    setSensitivityAnalysisLoading(true);

    const arGeometry: Geometry[] = [];

    arIntersectionsLayer.removeAll();

    /* Draw shapes from the sensitivity issues response.*/
    mapFeatureSet.features.forEach((g) => {
      arGeometry.push(g.geometry);
    });

    const combinedARGeometry = union(arGeometry);

    const mappedSensitivityIssues: SensitivityIssueRecord[] = [];

    Promise.all(
      sensitivityRules.map((rule) => {
        return executeSensitivityQuery(rule, combinedARGeometry);
      })
    )
      .then((results) => {
        results.forEach((result) => {
          // only include the result if there were sensitive features found
          if (result.sensitivityResults.length > 0) {
            // collate and store the results (by rule category)

            // draw each of the impacted features on the map
            result.sensitivityResults.forEach((i) => {
              drawShapes(arIntersectionsLayer, i.intersectingGeometry!, intersectionSymbol);

              switch (result.sensitivityType) {
                case SensitivityType.Intersecting:
                  drawShapes(arIntersectionsLayer, i.sensitivityResultGeometry, sensitivitySymbol);
                  mappedSensitivityIssues.push({
                    label: result.getLabel(result.name, i.graphic.attributes),
                    extent: i.intersectingGeometry!.extent
                  });
                  break;
                case SensitivityType.NonIntersecting:
                  drawShapes(arIntersectionsLayer, i.sensitivityResultGeometry, intersectionSymbol);
                  mappedSensitivityIssues.push({
                    label: result.getLabel(result.name, i.graphic.attributes),
                    extent: i.sensitivityResultGeometry!.extent
                  });
                  break;
              }
            });
          }
        });
        patchIsMapSensitivityAnalysed();
      })
      .finally(() => {
        mapView.goTo(viewExtent);
        setSensitivityAnalysisLoading(false);
        setShowSensitivityList(true);
        setSensitivityIssuesList(mappedSensitivityIssues);
      });
  }, [
    mapFeatureSetByShapeId,
    mapView,
    arIntersectionsLayer,
    sensitivityRules,
    patchIsMapSensitivityAnalysed,
    intersectionSymbol,
    sensitivitySymbol,
    viewExtent
  ]);

  function setMapScale(view: MapView) {
    // Set the minimum and maximum scale
    view.constraints = {
      minScale: 9000000,
      maxScale: 35
    };
  }

  const addCustomWidgetPanels = (view: MapView) => {
    const toolboxWidget = new Expand({
      view: view,
      content: mapToolboxPanel,
      expanded: true
    });

    const sensitivityBoxWidget = new Feature({
      view: view,
      container: sensitivityPanel
    });

    view.ui.add(toolboxWidget, "top-left");
    view.ui.add(sensitivityBoxWidget, "top-right");
  };

  const addMapWidgets = (view: MapView) => {
    BOTTOM_RIGHT_WIDGETS.map((item) => {
      const widgetPosition = "bottom-right";
      const WidgetComponent = new item.component({ view: view, ...item.componentProps });
      if (!item.isExpandable) view.ui.add(WidgetComponent, widgetPosition);
      else
        view.ui.add(
          new Expand({
            content: WidgetComponent,
            view: view,
            expandTooltip: `Open ${item.name}`,
            collapseTooltip: `Close ${item.name}`,
            autoCollapse: true
          }),
          widgetPosition
        );
    });
  };

  const setZoomToARShape = useCallback(
    async (view: MapView) => {
      const mapFeatureSet = await mapFeatureSetByShapeId;
      if (!mapFeatureSet) return;

      // clear existing shapes from the graphics layer
      arGraphicsLayer.removeAll();

      if (mapFeatureSet.features.length > 0) {
        // get the selected features and add the geometry to the graphics layer
        mapFeatureSet.features.map(function (feature) {
          arGraphicsLayer.add(
            new Graphic({
              geometry: feature.geometry,
              symbol: arSymbol
            })
          );
        });

        // select the AR Version we want using a SQL expression
        const query = mapFeatureLayer.createQuery();
        query.where = `AR_Shape_ID = '${arMap?.id}'`;

        // zoom to the extent of the selected features
        mapFeatureLayer.queryExtent(query).then((response) => {
          const extent = response.extent.expand(1.3);

          view.goTo(extent).then(() => {
            setViewExtent(extent);
          });
        });
      }
    },
    [arGraphicsLayer, arMap?.id, arSymbol, mapFeatureLayer, mapFeatureSetByShapeId]
  );

  const onArcgisViewReadyChange = (event: ArcgisMapCustomEvent<void>) => {
    const view = event.target.view;
    if (view) {
      view.map.add(arGraphicsLayer);
      view.map.add(arIntersectionsLayer);

      setMapLoaded(true);
      setMapScale(view);
      addCustomWidgetPanels(view);
      addMapWidgets(view);
      setZoomToARShape(view);
      setMapView(view);
    }
  };

  const uploadFile = (fileToUpload: File) => {
    setUploadInProgress(true);
    setMapFileUploading(true);

    const fileData = new FormData();
    fileData.append("file", fileToUpload);

    const addMapCommand: AddApprovalRequestMap = {
      approvalRequestId,
      fileData
    };

    updateMapMutation(addMapCommand, {
      onSettled: () => {
        setUploadInProgress(false);
        setMapFileUploading(false);
      }
    });
  };

  const downloadMap = useCallback(() => {
    setMapFileDownloading(true);

    downloadMapFile(undefined, {
      onSettled: () => {
        setMapFileDownloading(false);
      }
    });
  }, [downloadMapFile]);

  useEffect(() => {
    if (errorMessage) {
      appendAlertBanner({
        ...AR_MAP_UPLOAD_ERROR_BANNER,
        message: errorMessage
      });
    } else {
      removeAlertBanner([AR_MAP_UPLOAD_ERROR_BANNER.id]);
    }
  }, [appendAlertBanner, errorMessage, removeAlertBanner]);

  useEffect(() => {
    if (mapView === undefined) {
      return;
    }

    mapToolboxRoot.render(
      <MapToolbox
        setFullScreen={setFullScreen}
        mapView={mapView}
        approvalRequestStatus={approvalRequest.approvalRequestStatus}
        isRequestor={isRequestor}
        viewExtent={viewExtent}
        getSensitivityIssues={() => {
          runSensitivityAnalysis();
        }}
        downloadMap={downloadMap}
        uploadLoading={mapFileUploading}
        downloadLoading={mapFileDownloading}
        sensitivityAnalysisLoading={sensitivityAnalysisLoading}
      />
    );
  }, [
    approvalRequest.approvalRequestStatus,
    downloadMap,
    isRequestor,
    mapFileDownloading,
    mapFileUploading,
    mapToolboxRoot,
    mapView,
    runSensitivityAnalysis,
    sensitivityAnalysisLoading,
    viewExtent
  ]);

  useEffect(() => {
    if (mapView === undefined) {
      return;
    }

    sensitivityRoot.render(
      <MapSensitivityOverlay
        showList={showSensitivityList}
        hideList={() => {
          setShowSensitivityList(false);
          setSensitivityIssuesList([]);
          arIntersectionsLayer.removeAll();
        }}
        sensitivityIssues={sensitivityIssuesList}
        mapView={mapView}
      />
    );
  }, [arIntersectionsLayer, arMap, mapView, sensitivityIssuesList, sensitivityRoot, showSensitivityList, viewExtent]);

  useEffect(() => {
    if (mapView) {
      setZoomToARShape(mapView);
      arIntersectionsLayer.removeAll();
      setShowSensitivityList(false);
    }
  }, [arIntersectionsLayer, mapView, setZoomToARShape]);

  return (
    <Grid
      className={fullScreen ? "ar-map-fullscreen" : ""}
      data-testid="map-render-display"
      visibility={mapLoaded ? "visible" : "hidden"}
      sx={{ flexDirection: "column", flexGrow: 1, height: "100%" }}
      style={{ margin: "0 auto" }}
    >
      <UploadComponent
        handleUpload={uploadFile}
        acceptedFileExtensions={ACCEPTED_MAP_FILE_EXTENSIONS}
        allowMultipleFile={false}
        setErrorMessage={setErrorMessage}
        validateMapFile={true}
      />
      <ArcgisMap itemId={REACT_APP_ARCGIS_WEBMAP_ITEM_ID} onArcgisViewReadyChange={onArcgisViewReadyChange} />
    </Grid>
  );
}
