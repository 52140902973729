import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { DataGridPro, GridColDef, GridEventListener, GridRowParams, GridSortModel } from "@mui/x-data-grid-pro";
import { useNavigate } from "react-router-dom";
import { formatDate } from "@/utils/dateHelper";
import { generatePath } from "react-router-dom";

import { APPROVAL_REQUEST_PATH, DEFAULT_AR_FILTER_QUERY } from "@/constants";
import { ApprovalRequestStatus, Cycle } from "@/interfaces";
import { useApprovalRequestList, usePagination, useUnreadIndicator } from "@/hooks";
import { flattenPaginatedARList, renderDisciplineStatusIcon } from "@/utils";
import { ARFilterToolbar, ARFilterQuery } from "@/components/toolbars";

export const ReviewRequired = () => {
  const navigate = useNavigate();

  const { currentPage, pageSize, paginationModelChangeHandler } = usePagination();

  const [sortField, setSortField] = useState<string>();
  const [filterQuery, setFilterQuery] = useState<ARFilterQuery>(DEFAULT_AR_FILTER_QUERY);

  const approvalRequestStatusFilter = [ApprovalRequestStatus.Submitted];

  const { data: approvalRequestList, isLoading } = useApprovalRequestList({
    approvalRequestStatus: approvalRequestStatusFilter,
    pageNumber: currentPage + 1,
    pageSize,
    sortField,
    hubId: filterQuery.selectedHub,
    projectId: filterQuery.selectedProject,
    siteId: filterQuery.selectedSite
  });

  const { isUnread } = useUnreadIndicator(approvalRequestList.items);

  const dataColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "",
      flex: 0.01,
      sortable: false,
      renderCell: ({ value }) => (
        <Box
          data-testid={`unread-indicator-${value}`}
          width="0.5rem"
          height="0.5rem"
          display="block"
          borderRadius="100%"
          sx={{ background: "red" }}
          visibility={isUnread(value) ? "visible" : "hidden"}
        />
      )
    },
    {
      field: "referenceNo",
      headerName: "AR Number",
      flex: 0.25
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1
    },
    {
      field: "top",
      headerName: "TOP",
      description: "TO Partnerships",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "biological",
      headerName: "B",
      description: "Biological",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "environmentalOps",
      headerName: "EO",
      description: "Environmental Ops",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "tenure",
      headerName: "T",
      description: "Tenure",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "stateAgreement",
      headerName: "SA",
      description: "State Agreement",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "miningAct",
      headerName: "M",
      description: "Mining Act",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "water",
      headerName: "W",
      description: "Water",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "environmentalApprovals",
      headerName: "EA",
      description: "Environmental Approvals",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "partV",
      headerName: "V",
      description: "Part V",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "nvcp",
      headerName: "NVCP",
      description: "Native Vegetation Clearing Permit",
      headerAlign: "center",
      sortable: false,
      flex: 0.15,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "shire",
      headerName: "Sh",
      description: "Shire",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "businessUnitName",
      headerName: "Business Unit",
      flex: 0.35
    },
    {
      field: "cycle",
      headerName: "Cycle",
      valueFormatter: (params) => Cycle[params.value],
      flex: 0.35
    },
    {
      field: "requiredByDate",
      headerName: "Required By",
      valueFormatter: (params) => formatDate(params.value),
      flex: 0.35
    }
  ];

  const arListWithDisciplineStatuses = useMemo(
    () => flattenPaginatedARList(approvalRequestList),
    [approvalRequestList]
  );

  const handleRowClick: GridEventListener<"rowClick"> = async ({ row: { id } }: GridRowParams) => {
    navigate(generatePath(APPROVAL_REQUEST_PATH.DETAILS, { approvalRequestId: id }));
  };

  const handleSortModelChange = (sortModel: GridSortModel) => {
    const sortClause = sortModel
      .map(({ field, sort }) => {
        if (["hub", "site"].includes(field)) {
          return `${field}.name ${sort}`;
        }
        return `${field} ${sort}`;
      })
      .join(",");

    setSortField(sortClause);
  };

  return (
    <DataGridPro
      autoHeight={true}
      columns={dataColumns}
      data-testid="review-required-table"
      density="compact"
      disableColumnMenu={true}
      loading={isLoading}
      onPaginationModelChange={paginationModelChangeHandler}
      onRowClick={handleRowClick}
      onSortModelChange={handleSortModelChange}
      pageSizeOptions={[10, 25, 50, 100]}
      pagination={true}
      paginationMode="server"
      paginationModel={{
        pageSize: approvalRequestList.pageSize,
        page: approvalRequestList.pageNumber - 1
      }}
      rowCount={approvalRequestList.totalRecords ?? 0}
      rows={arListWithDisciplineStatuses}
      slots={{ toolbar: ARFilterToolbar }}
      slotProps={{
        toolbar: {
          onFilterChange: (filterQuery: ARFilterQuery) => {
            setFilterQuery(filterQuery);
          },
          approvalRequestStatusFilter
        }
      }}
      sortingMode="server"
      sx={{ border: 0 }}
    />
  );
};
