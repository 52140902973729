import { useMemo, useState } from "react";
import { FormHelperText, Grid, Tab, Tabs } from "@mui/material";
import { useFormContext } from "react-hook-form";
import dayjs from "dayjs";

import {
  AR_BASIC_DETAILS_LOCATORS,
  EXTEND_BY_OPTIONS,
  FORM_HELPER_ERROR_CLASS,
  HELPER_TEXT,
  MANDATORY_FIELD
} from "@/constants";
import { ApprovalRequestStatus, ARFormValues, Cycle } from "@/interfaces";
import { useARContext, useAuthorization } from "@/context";
import { useBusinessUnitList, useHubList, useProjectList, useSitesByHubId } from "@/hooks";
import { processRTEValue } from "@/utils";
import {
  ARAwareReadOnlyAppFormInput,
  ARAwareReadOnlyAppFormSelect,
  ARAwareReadOnlyAppFormDateInput,
  ARAwareReadOnlyAppFormRichTextInput
} from "@/components/fields";
import { RichTextDiffEditor } from "@/components/diff-viewers/RichTextDiffViewer";
import { ARAwareReadOnlyQuestionAnswers } from "./question-answer/ARQuestionAnswers";
import { ARAwareReadOnlyWorkCategory } from "./work-category";
import { ARDetailsOverview } from "./ARDetailsOverview";
import { ARDetailsChanges } from "./ARDetailsChanges";

export function ARDetailsForm() {
  const [tabIndex, setTabIndex] = useState(0);
  const [detailHighlightKey, setDetailHighlightKey] = useState<string>("");
  const [descriptionOriginalValue, setDescriptionOriginalValue] = useState<string>("");
  const [descriptionCurrentValue, setDescriptionCurrentValue] = useState<string>("");
  const [questionHighlightId, setQuestionHighlightId] = useState<string>("");

  const {
    control,
    formState: { errors },
    getValues,
    clearErrors,
    resetField
  } = useFormContext<ARFormValues>();

  const { isCoordinator } = useAuthorization();

  const { alertBanners, approvalRequest, removeAlertBanner, uploadInProgress } = useARContext();

  const isExtensionAR = useMemo(() => {
    return approvalRequest.cycle !== undefined && approvalRequest.cycle === Cycle.Extension;
  }, [approvalRequest]);

  const { data: hubList } = useHubList();

  const { data: siteList, isSuccess: slSuccess } = useSitesByHubId(getValues("hubId"));

  const { data: businessUnitList } = useBusinessUnitList(true);

  const { data: projectList } = useProjectList(true);

  const resetErrors = (name?: keyof ARFormValues) => {
    if (name === undefined) {
      clearErrors();
    }

    if (name && errors[name]) {
      clearErrors(name);
    }

    if (Object.values(errors).length === 0 && !uploadInProgress) {
      const alertBannerIdsToRemove = alertBanners.filter((entry) => !entry.isPersist).map((entry) => entry.id);
      removeAlertBanner(alertBannerIdsToRemove);
    }
  };

  const onTabIndexChange = (newValue: number) => {
    if (newValue === 0) {
      setDetailHighlightKey("");
      setQuestionHighlightId("");
    }

    setTabIndex(newValue);
  };

  const isTabDisabled =
    approvalRequest.currentVersion === "0.1" && approvalRequest.approvalRequestStatus < ApprovalRequestStatus.Submitted;

  return (
    <Grid container>
      <Grid item sm={8} py={2} pr={2}>
        <Grid container>
          <Grid item sm={12}>
            <ARAwareReadOnlyAppFormInput
              control={control}
              label={"Title"}
              name={"title"}
              error={errors.title}
              data-testid={AR_BASIC_DETAILS_LOCATORS.TITLE_FIELD}
              inputProps={{ maxLength: 250 }}
              margin="none"
              size="medium"
              resetError={() => {
                resetErrors("title");
              }}
              formHelperErrorClass={FORM_HELPER_ERROR_CLASS.AR_DETAILS}
              isHighlight={detailHighlightKey === AR_BASIC_DETAILS_LOCATORS.TITLE_FIELD}
              readOnly={isExtensionAR}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <ARAwareReadOnlyAppFormSelect
              control={control}
              label={"Hub"}
              name={"hubId"}
              error={errors.hubId}
              isOptional={true}
              data-testid={AR_BASIC_DETAILS_LOCATORS.HUB_FIELD}
              showError={true}
              options={hubList}
              margin="none"
              size="medium"
              resetError={() => {
                resetErrors("hubId");
              }}
              onChangeHandler={() => {
                resetField("siteId", {
                  keepDirty: true,
                  keepTouched: true,
                  keepError: true,
                  defaultValue: ""
                });
              }}
              formHelperErrorClass={FORM_HELPER_ERROR_CLASS.AR_DETAILS}
              isHighlight={detailHighlightKey === AR_BASIC_DETAILS_LOCATORS.HUB_FIELD}
              readOnly={isExtensionAR}
            />
          </Grid>
          <Grid item sm={6}>
            <ARAwareReadOnlyAppFormSelect
              control={control}
              label={"Site"}
              name={"siteId"}
              isOptional={true}
              error={errors.siteId}
              data-testid={AR_BASIC_DETAILS_LOCATORS.SITE_FIELD}
              showError={true}
              options={siteList}
              margin="none"
              size="medium"
              disabled={!slSuccess}
              resetError={() => {
                resetErrors("siteId");
              }}
              formHelperErrorClass={FORM_HELPER_ERROR_CLASS.AR_DETAILS}
              isHighlight={detailHighlightKey === AR_BASIC_DETAILS_LOCATORS.SITE_FIELD}
              readOnly={isExtensionAR}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <ARAwareReadOnlyAppFormSelect
              control={control}
              label={"Business Unit"}
              name={"businessUnitId"}
              isOptional={true}
              error={errors.businessUnitId}
              data-testid={AR_BASIC_DETAILS_LOCATORS.BUSINESS_UNIT_FIELD}
              showError={true}
              options={businessUnitList}
              margin="none"
              size="medium"
              resetError={() => {
                resetErrors("businessUnitId");
              }}
              formHelperErrorClass={FORM_HELPER_ERROR_CLASS.AR_DETAILS}
              isHighlight={detailHighlightKey === AR_BASIC_DETAILS_LOCATORS.BUSINESS_UNIT_FIELD}
              readOnly={isExtensionAR}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item sm={6} alignContent="center" alignSelf="center">
            <ARAwareReadOnlyAppFormSelect
              control={control}
              label={"Project"}
              name={"projectId"}
              error={errors.projectId}
              isOptional={true}
              data-testid={AR_BASIC_DETAILS_LOCATORS.PROJECT_FIELD}
              showError={true}
              options={projectList}
              margin="none"
              size="medium"
              resetError={() => {
                resetErrors("projectId");
              }}
              formHelperErrorClass={FORM_HELPER_ERROR_CLASS.AR_DETAILS}
              isHighlight={detailHighlightKey === AR_BASIC_DETAILS_LOCATORS.PROJECT_FIELD}
              readOnly={isExtensionAR}
            />
          </Grid>
          <Grid item sm={6} alignContent="center" alignSelf="center">
            <ARAwareReadOnlyAppFormInput
              control={control}
              label={"Cost Code"}
              name={"costCode"}
              error={errors.costCode}
              data-testid={AR_BASIC_DETAILS_LOCATORS.COST_CODE_FIELD}
              inputProps={{ maxLength: 50 }}
              margin="none"
              size="medium"
              resetError={() => {
                resetErrors("costCode");
              }}
              formHelperErrorClass={FORM_HELPER_ERROR_CLASS.AR_DETAILS}
              isHighlight={detailHighlightKey === AR_BASIC_DETAILS_LOCATORS.COST_CODE_FIELD}
              readOnly={isExtensionAR ? false : undefined}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ display: "flex", alignItems: "flex-start" }}>
          <Grid item sm={6}>
            <ARAwareReadOnlyAppFormDateInput
              name={"requiredByDate"}
              control={control}
              label={"Required By"}
              data-testid={AR_BASIC_DETAILS_LOCATORS.REQUIRED_BY_DATE_FIELD}
              error={errors.requiredByDate}
              minDate={isCoordinator ? undefined : dayjs().add(4, "months")}
              helperText={!isCoordinator ? HELPER_TEXT.REQUIRED_BY_DATE : undefined}
              resetError={() => resetErrors("requiredByDate")}
              formHelperErrorClass={FORM_HELPER_ERROR_CLASS.AR_DETAILS}
              isHighlight={detailHighlightKey === AR_BASIC_DETAILS_LOCATORS.REQUIRED_BY_DATE_FIELD}
              readOnly={isExtensionAR ? false : undefined}
            />
          </Grid>
          {isExtensionAR ? (
            <Grid item sm={6} alignContent="center" alignSelf="center">
              <ARAwareReadOnlyAppFormSelect
                control={control}
                label={"Extend By"}
                name={"extendBy"}
                isOptional={true}
                options={EXTEND_BY_OPTIONS}
                data-testid="extendby-dropdown"
                readOnly={isExtensionAR ? false : undefined}
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid container spacing={3}>
          <Grid item sm={12} alignContent="center" alignSelf="center">
            {detailHighlightKey === AR_BASIC_DETAILS_LOCATORS.DESCRIPTION_FIELD ? (
              <RichTextDiffEditor originalValue={descriptionOriginalValue} currentValue={descriptionCurrentValue} />
            ) : (
              <ARAwareReadOnlyAppFormRichTextInput
                name={"description"}
                control={control}
                error={errors.description}
                defaultValue={processRTEValue(approvalRequest.description)}
                resetError={() => {
                  resetErrors("description");
                }}
                data-testid={AR_BASIC_DETAILS_LOCATORS.DESCRIPTION_FIELD}
                formHelperErrorClass={FORM_HELPER_ERROR_CLASS.AR_DETAILS}
                isHighlight={detailHighlightKey === AR_BASIC_DETAILS_LOCATORS.DESCRIPTION_FIELD}
                readOnly={isExtensionAR}
              />
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item sm={12} alignContent="center" alignSelf="center">
            <ARAwareReadOnlyQuestionAnswers
              readOnly={isExtensionAR}
              errors={errors.approvalRequestAnswers}
              resetError={() => {
                resetErrors("allQuestionsAnswered");
                resetErrors("approvalRequestAnswers");
              }}
              formHelperErrorClass={FORM_HELPER_ERROR_CLASS.AR_DETAILS}
              questionHightlightId={questionHighlightId}
            />
            {errors.allQuestionsAnswered?.message ? (
              <FormHelperText error className={FORM_HELPER_ERROR_CLASS.AR_DETAILS}>
                {errors.allQuestionsAnswered.message}
              </FormHelperText>
            ) : null}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item sm={12} alignContent="center" alignSelf="center">
            <ARAwareReadOnlyWorkCategory
              readOnly={isExtensionAR}
              resetError={() => {
                resetErrors("approvalRequestWorkCategories");
              }}
            />
            {errors.approvalRequestWorkCategories ? (
              <FormHelperText error className={FORM_HELPER_ERROR_CLASS.AR_DETAILS}>
                {MANDATORY_FIELD.WC_SECTION}
              </FormHelperText>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={4} px="1.5rem">
        <Tabs value={tabIndex} onChange={(_, newValue) => onTabIndexChange(newValue)}>
          <Tab label="Overview"></Tab>
          <Tab label="Changes" disabled={isTabDisabled}></Tab>
        </Tabs>
        {tabIndex === 0 ? <ARDetailsOverview /> : null}
        {tabIndex === 1 ? (
          <ARDetailsChanges
            correspondArDetailHighlightKey={detailHighlightKey}
            updateCorrespondArDetailFieldKey={setDetailHighlightKey}
            updateDescriptionOriginalValue={setDescriptionOriginalValue}
            updateDescriptionCurrentValue={setDescriptionCurrentValue}
            questionHighlightId={questionHighlightId}
            updateQuestionHighlightId={setQuestionHighlightId}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}
