import JSZip from "jszip";

import {
  ACCEPTED_COMPULSORY_SHAPE_FILE_EXTENSIONS,
  ACCEPTED_SHAPE_FILE_EXTENSIONS,
  ACCEPTED_ZIP_FILE_TYPES,
  ATTACHMENT_MAP_ERRORS
} from "@/constants";

export const validateMapUploadFile = async (fileList: FileList, validationFailed: (errorMessage: string) => void) => {
  const file = fileList[0];

  if (!ACCEPTED_ZIP_FILE_TYPES.includes(file.type)) {
    validationFailed(ATTACHMENT_MAP_ERRORS.INVALID_ZIP_FILE);
    return;
  }

  if (file.size > 5 * 1024 * 1024) {
    validationFailed(ATTACHMENT_MAP_ERRORS.INVALID_FILE_SIZE);
    return;
  }

  try {
    const zip = await JSZip.loadAsync(file);
    let hasCompulsoryFile = false;
    let allFilesAllowed = true;
    for (const relativePath in zip.files) {
      const file = zip.files[relativePath];
      // Get the file name
      if (relativePath) {
        const fileName = file.name;

        // Determine the file type based on the file extension
        const fileType = fileName.split(".").pop();
        const fileExtension = "." + fileType;

        if (fileExtension) {
          if (!hasCompulsoryFile) {
            if (ACCEPTED_COMPULSORY_SHAPE_FILE_EXTENSIONS.includes(fileExtension)) {
              hasCompulsoryFile = true;
            }
          }

          // Check if this entry has an allowed extension
          if (!ACCEPTED_SHAPE_FILE_EXTENSIONS.includes(fileExtension)) {
            allFilesAllowed = false;
            break; // No need to check further if one file is invalid
          }
        }
      }
    }
    if (!hasCompulsoryFile) {
      validationFailed(ATTACHMENT_MAP_ERRORS.INVALID_MISSING_FILE);
      return;
    } else if (!allFilesAllowed) {
      validationFailed(ATTACHMENT_MAP_ERRORS.INVALID_FILE_CONTENTS);
      return;
    }
  } catch (error) {
    validationFailed(ATTACHMENT_MAP_ERRORS.INVALID_FILE);
    return;
  }
};
