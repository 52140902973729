import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { Stack, Typography } from "@mui/material";

import { ARFormValues } from "@/interfaces";
import { colours } from "@/theme/colour";
import { Dialog } from "@/components/dialogs";
import { AppFormInput } from "@/components/fields";

interface SensitivityBypassFormProps {
  dialogOpen: boolean;
  dismissDialog: () => void;
  submitOnClickHandler: () => void;
}

export function SensitivityBypassForm({ dialogOpen, dismissDialog, submitOnClickHandler }: SensitivityBypassFormProps) {
  const { control, watch, reset } = useFormContext<ARFormValues>();

  const [reason] = watch(["sensitivityBypassReason"]);

  const submitButtonDisabled = useMemo(() => {
    return !reason || reason.trim() === "";
  }, [reason]);

  const handleDialogClose = () => {
    dismissDialog();
    reset();
  };

  const handleSubmitClick = () => {
    submitOnClickHandler();
    dismissDialog();
  };

  return (
    <Dialog
      data-testid="sensitivity-bypass-dialog"
      open={dialogOpen}
      onClose={handleDialogClose}
      title={"Sensitive Areas Issues Exist"}
      actions={[
        {
          label: "Cancel",
          onClick: handleDialogClose
        },
        {
          label: "Submit",
          disabled: submitButtonDisabled,
          onClick: handleSubmitClick,
          isFormSubmit: true
        }
      ]}
    >
      <Stack rowGap={2}>
        <Typography color={colours.dialog.subtextColour}>
          Assessment of the request will be lengthy while disciplines resolve these issues.
        </Typography>
        <AppFormInput
          margin={"normal"}
          control={control}
          label={"Reason"}
          name={"sensitivityBypassReason"}
          required={true}
          data-testid="reason-input"
          multiline={true}
          rows={3}
          maxLength={500}
        />
      </Stack>
    </Dialog>
  );
}
