import { useMutation, useQueryClient } from "@tanstack/react-query";

import { apiClient } from "@/api/client";
import { SensitivityRule, SensitivityType } from "@/interfaces";
import { APPROVAL_REQUEST_MAP_QUERY_KEY } from "@/constants";

const basePath = "/ApprovalRequestMap";

export function usePatchIsMapSensitivityAnalysed(approvalRequestId: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => apiClient.patch(`${basePath}/${approvalRequestId}/sensitivity-analysed`),
    onSuccess: () => {
      queryClient.invalidateQueries([APPROVAL_REQUEST_MAP_QUERY_KEY, approvalRequestId]);
    }
  });
}

export function useSensitivityRules() {
  const sensitivityRules: SensitivityRule[] = [
    {
      active: true,
      sensitivityType: SensitivityType.Intersecting,
      name: "Heritage",
      layer_urls: [
        "https://gismaptest.corp.riotinto.org/arcgis/rest/services/Heritage/Integrated_Heritage_Management/FeatureServer/0"
      ],
      query: "Internal_Site_Status not in ('Cleared-CC', 'Cleared-D') and Approved_Impact = 'Not yet evaluated'",
      buffer: 25,
      display_fields: ["Internal_Site_Status", "Field_Site_No"],
      getLabel: (ruleName, attributes) => `${ruleName}, ${attributes?.Field_Site_No}`
    },
    {
      active: true,
      sensitivityType: SensitivityType.Intersecting,
      name: "Exclusion Sites",
      layer_urls: ["https://gismaptest.corp.riotinto.org/arcgis/rest/services/ADT/Approvals_ADT/MapServer/6"],
      query:
        "(Site_category = 'Biological Exclusion' or Site_Category = 'Biological Interim Exclusion') and Site_type <> 'Heritage'",
      buffer: 0,
      display_fields: ["Site_Category", "Field_Site_No", "Reason"],
      getLabel: (ruleName, attributes) => `${ruleName}, Exclusion, ${attributes?.Field_Site_No}`
    },
    {
      active: true,
      sensitivityType: SensitivityType.Intersecting,
      name: "Rio Tinto Pastoral Station",
      layer_urls: ["https://gismaptest.corp.riotinto.org/arcgis/rest/services/ADT/Tenure_LandAct_ADT/MapServer/1"],
      query: "Land_Use_Type = 'Pastoral Station'",
      buffer: 0,
      display_fields: ["Lease_Id"],
      getLabel: (ruleName, attributes) => `${ruleName}, ${attributes?.Lease_Id}`
    },
    {
      active: true,
      sensitivityType: SensitivityType.NonIntersecting,
      name: "Rio Tinto Tenure",
      layer_urls: [
        "https://gismaptest.corp.riotinto.org/arcgis/rest/services/ADT/Tenure_MiningAct_ADT/MapServer/0", // Mining Act Live
        "https://gismaptest.corp.riotinto.org/arcgis/rest/services/ADT/Tenure_LandAct_ADT/MapServer/0", // Land Act General Lease
        "https://gismaptest.corp.riotinto.org/arcgis/rest/services/ADT/Tenure_LandAct_ADT/MapServer/1", // Land Act Pastoral Station
        "https://gismaptest.corp.riotinto.org/arcgis/rest/services/ADT/Tenure_LandAct_ADT/MapServer/2" // Land Act Freehold Lease
      ],
      query: "",
      buffer: 0,
      display_fields: [],
      getLabel: (_ruleName, _attributes) => `Tenure: outside of live RTIO Mining Act Tenure and/or Land Act Tenure`
    }
  ];

  return sensitivityRules;
}
