import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useNavigate, useParams } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import { useAtomValue, useSetAtom } from "jotai";
import { ValidationError } from "yup";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import FeatureSet from "@arcgis/core/rest/support/FeatureSet";

import { AR_UNEXPECTED_ERROR_BANNER, DISCIPLINE_MESSAGE, PATH, DEFAULT_LOADER_CONFIG } from "@/constants";
import {
  AlertBannerConfig,
  APIException,
  ApprovalRequestBase,
  ApprovalRequestType,
  ApprovalRequestWorkCategoryItem,
  ARDiscipline,
  ARFormValues,
  DisciplineAuthorisationComment,
  DisciplineTrackingComment,
  DisciplineTrackingCommentError,
  IOption,
  QuestionAnswer,
  UpdateApprovalRequest,
  WorkSubCategoryItem,
  LoaderConfig,
  MapItem
} from "@/interfaces";
import { convertFromUTC, filterAndMapToQAAnswerItem, processRTEValue } from "@/utils";

import { isAuthorisationCommentUpdatableAtom, isTrackingCommentUpdatableAtom, snackBarAtom } from "@/stores";
import {
  useApprovalRequestById,
  useApprovalRequestTypes,
  useARDisciplines,
  useAuthoriseDiscipline,
  useDeleteApprovalRequest,
  useGetApprovalRequestMap,
  useQuestionAndAnswers,
  useUpdateApprovalRequest,
  useUpdateDisciplineComments,
  useWorkCategories,
  useWorkCategoryAnswers,
  useWorkSubCategories
} from "@/hooks";
import { trackingCommentsSchema } from "@/validations";
import { Loading } from "@/components";

import { useAuthorization } from "./AuthorizationProvider";

export interface ApprovalRequestContextType {
  /* Data */
  approvalRequest: ApprovalRequestBase;
  approvalRequestDisciplineId?: string;
  approvalRequestDisciplines: ARDiscipline[];
  approvalRequestId: string;
  approvalRequestType: ApprovalRequestType;
  fetchedARWorkCategoryList: ApprovalRequestWorkCategoryItem[];
  questionsAnswers: QuestionAnswer[];
  workCategories: IOption[];
  workSubCategories: WorkSubCategoryItem[];
  arMap: MapItem | undefined;
  mapFeatureLayer: FeatureLayer;
  mapFeatureSetByShapeId: Promise<FeatureSet | undefined>;

  /* Flags */
  arFetched: boolean;
  allQuestionsAnswered: boolean;
  isARDirty: boolean;
  qaFetched: boolean;
  uploadInProgress: boolean;
  userIsSMEOfAr: boolean;
  userIsSMEOfDiscipline: boolean;
  userIsSMEOfDisciplineOrAdmin: boolean;

  /* States */
  alertBanners: AlertBannerConfig[];
  allAuthorisationComments: Record<ARDiscipline["id"], DisciplineAuthorisationComment[]>;
  allTrackingCommentErrors: Record<ARDiscipline["id"], DisciplineTrackingCommentError[]>;
  allTrackingComments: Record<ARDiscipline["id"], DisciplineTrackingComment[]>;
  dirtyTabs: Record<string, boolean>;
  ownedTabs: string[];
  loader: LoaderConfig;

  /* Methods */
  appendAlertBanner: (alertBannerConfig: AlertBannerConfig) => void;
  authoriseDisciplineHandler: () => void;
  removeAlertBanner: (ids: string[]) => void;
  resetAlertBanner: (exemptions: AlertBannerConfig["id"][]) => void;
  saveApprovalRequest: (
    update: UpdateApprovalRequest,
    onSave?: (updatedAR: ApprovalRequestBase) => void,
    onError?: (error: APIException) => void
  ) => void;
  deleteApprovalRequest: (onError?: () => void) => void;
  saveDisciplineTabs: (onSuccess?: () => void, onFail?: () => void) => void;
  setAllQuestionsAnswered: Dispatch<SetStateAction<boolean>>;
  setUploadInProgress: Dispatch<SetStateAction<boolean>>;
  updateAllAuthorisationComments: (
    updatedTabs: ApprovalRequestContextType["allAuthorisationComments"],
    isDirty?: boolean
  ) => void;
  resetDisciplineTab: (approvalRequestDisciplineId: string) => void;
  updateAllTrackingComments: (
    updatedTabs: ApprovalRequestContextType["allTrackingComments"],
    isDirty?: boolean
  ) => void;
  updateAllTrackingCommentErrors: Dispatch<SetStateAction<ApprovalRequestContextType["allTrackingCommentErrors"]>>;
  updateLoader: (loaderConfig: LoaderConfig) => void;
  resetLoader: () => void;
}

const { REACT_APP_ARCGIS_RTIO_FEATURE_LAYER } = import.meta.env;

export const ApprovalRequestContext = createContext<ApprovalRequestContextType | undefined>(undefined);

export const useARContext = () => {
  const context = useContext(ApprovalRequestContext);

  if (context === undefined) {
    throw new Error("Cannot use 'ApprovalRequestContext' without an 'ApprovalRequestContextProvider'.");
  }

  return context;
};

export function ApprovalRequestContextProvider({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  const { approvalRequestId, approvalRequestDisciplineId } = useParams();
  const { userId, isAdmin, isSME } = useAuthorization();

  const [dirtyTabs, setDirtyTabs] = useState<Record<string, boolean>>({});
  const [alertBanners, setAlertBanners] = useState<AlertBannerConfig[]>([]);
  const [loader, setLoader] = useState<LoaderConfig>(DEFAULT_LOADER_CONFIG);
  const [allTrackingComments, setAllTrackingComments] = useState<ApprovalRequestContextType["allTrackingComments"]>({});
  const [allTrackingCommentErrors, setAllTrackingCommentErrors] = useState<
    ApprovalRequestContextType["allTrackingCommentErrors"]
  >({});
  const [allAuthorisationComments, setAllAuthorisationComments] = useState<
    ApprovalRequestContextType["allAuthorisationComments"]
  >({});
  const [isAuthorised, setIsAuthorised] = useState<boolean>(false);
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState<boolean>(false);

  const setSnackBar = useSetAtom(snackBarAtom);
  const isTrackingCommentUpdatable = useAtomValue(isTrackingCommentUpdatableAtom);
  const isAuthorisationCommentUpdatable = useAtomValue(isAuthorisationCommentUpdatableAtom);

  const { data: approvalRequest, isFetched: arFetched } = useApprovalRequestById(approvalRequestId);
  const { data: approvalRequestDisciplines, isFetched: arDisciplinesFetched } = useARDisciplines(approvalRequestId);
  const { data: approvalRequestTypes, isFetched: arTypesFetched } = useApprovalRequestTypes({ isEnabled: true });
  const { data: arMap, isFetched: arMapFetched } = useGetApprovalRequestMap(approvalRequestId);
  const { data: questionsAnswers, isFetched: qaFetched } = useQuestionAndAnswers(
    approvalRequestId,
    approvalRequest?.approvalRequestTypeId
  );
  const { data: workCategories, isFetched: wcFetched } = useWorkCategories(approvalRequest?.approvalRequestTypeId);
  const { data: workSubCategories, isFetched: wscFetched } = useWorkSubCategories();
  const { data: fetchedARWorkCategoryList, isFetched: arWCFetched } = useWorkCategoryAnswers(approvalRequestId);

  const { mutate: updateApprovalRequestHook } = useUpdateApprovalRequest(approvalRequestId!);
  const { mutate: deleteApprovalRequestHook } = useDeleteApprovalRequest();

  const defaultValues: Partial<ARFormValues> = {
    title: approvalRequest?.title,
    hubId: approvalRequest?.hubId,
    siteId: approvalRequest?.siteId,
    businessUnitId: approvalRequest?.businessUnitId,
    projectId: approvalRequest?.projectId,
    costCode: approvalRequest?.costCode ?? "",
    requiredByDate: approvalRequest?.requiredByDate ? convertFromUTC(approvalRequest.requiredByDate).toDate() : null,
    description: processRTEValue(approvalRequest?.description),
    allQuestionsAnswered,
    mapUploaded: arMapFetched && arMap?.id !== undefined,
    isMapSensitivityAnalysed: arMap?.isMapSensitivityAnalysed ?? false,
    approvalRequestAnswers: [],
    approvalRequestWorkCategories: [],
    extendBy: approvalRequest?.extendBy,
    sensitivityBypassReason: arMap?.sensitivityBypassReason,
    permitExpiryDate: approvalRequest?.permitExpiryDate,
    isEndorserOverride: false,
    permitDateOverrideReason: ""
  };

  const formMethods = useForm<ARFormValues>({
    defaultValues
  });

  const {
    formState: { isDirty: isARDetailsDirty },
    reset,
    getValues
  } = formMethods;

  useEffect(() => {
    if (arFetched && qaFetched && arWCFetched && arMapFetched) {
      const resetValues = {
        ...getValues(),
        ...approvalRequest,
        approvalRequestAnswers: filterAndMapToQAAnswerItem(questionsAnswers),
        approvalRequestWorkCategories: fetchedARWorkCategoryList,
        requiredByDate: approvalRequest?.requiredByDate
          ? convertFromUTC(approvalRequest.requiredByDate).toDate()
          : null,
        mapUploaded: arMap?.id !== undefined,
        isMapSensitivityAnalysed: arMap?.isMapSensitivityAnalysed ?? false,
        sensitivityBypassReason: arMap?.sensitivityBypassReason
      };
      reset(resetValues, {
        keepDirty: false,
        keepDirtyValues: false
      });

      if (loader?.open) {
        resetLoader();
      }
    }
  }, [
    approvalRequest,
    arFetched,
    arMap?.id,
    arMap?.isMapSensitivityAnalysed,
    arMapFetched,
    arWCFetched,
    fetchedARWorkCategoryList,
    getValues,
    qaFetched,
    questionsAnswers,
    reset
  ]);

  const isARDirty = useMemo(() => {
    return Object.values(dirtyTabs).some((tabIsDirty) => tabIsDirty) || isARDetailsDirty;
  }, [dirtyTabs, isARDetailsDirty]);

  // define the AR Feature Layer we will use for rendering selected AR shapes
  const mapFeatureLayer = useMemo(
    () =>
      new FeatureLayer({
        url: REACT_APP_ARCGIS_RTIO_FEATURE_LAYER
      }),
    []
  );

  const mapFeatureSetByShapeId = useMemo(async () => {
    if (!arMap?.id) {
      return undefined;
    }
    // select the AR Version we want using a SQL expression
    const ar_query = mapFeatureLayer.createQuery();
    ar_query.where = `AR_Shape_ID = '${arMap?.id}'`;
    return await mapFeatureLayer.queryFeatures(ar_query);
  }, [arMap?.id]);

  const ownedTabs = useMemo(() => {
    const DEFAULT_OWNED_TABS = ["details", "attachments", "collaboration", "history"];
    if (arDisciplinesFetched) {
      return approvalRequestDisciplines.reduce((tabs: string[], discipline) => {
        if ([discipline.primaryApprover, discipline.secondaryApprover].includes(userId ?? "") || isAdmin) {
          tabs.push(discipline.id);
        }
        return tabs;
      }, DEFAULT_OWNED_TABS);
    }

    return DEFAULT_OWNED_TABS;
  }, [approvalRequestDisciplines, arDisciplinesFetched, isAdmin, userId, mapFeatureLayer]);

  const userIsSMEOfDiscipline = useMemo(
    () => !!approvalRequestDisciplineId && ownedTabs.includes(approvalRequestDisciplineId) && isSME,
    [approvalRequestDisciplineId, isSME, ownedTabs]
  );

  const userIsSMEOfDisciplineOrAdmin = useMemo(
    () => userIsSMEOfDiscipline || isAdmin,
    [isAdmin, userIsSMEOfDiscipline]
  );

  const userIsSMEOfAr = useMemo(() => {
    return approvalRequestDisciplines.some((x) => ownedTabs.includes(x.id)) && isSME;
  }, [approvalRequestDisciplines, isSME, ownedTabs]);

  const appendAlertBanner = useCallback((alertBannerConfig: AlertBannerConfig) => {
    setAlertBanners((old) => {
      return [...filterAlertBanners(old, [alertBannerConfig.id]), alertBannerConfig];
    });
  }, []);

  const removeAlertBanner = useCallback((ids: string[]) => {
    setAlertBanners((old) => filterAlertBanners(old, ids));
  }, []);

  const resetAlertBanner = (exemptions: AlertBannerConfig["id"][]) => {
    if (exemptions) {
      setAlertBanners((old) => old.filter(({ id }) => exemptions.includes(id)));
    } else {
      setAlertBanners([]);
    }
  };

  const filterAlertBanners = (existingList: AlertBannerConfig[], ids: string[]) =>
    existingList.filter((entry) => !ids.includes(entry.id));

  const updateLoader = (loaderConfig: LoaderConfig) => {
    setLoader(loaderConfig);
  };

  const resetLoader = () => {
    setLoader(DEFAULT_LOADER_CONFIG);
  };

  const saveApprovalRequest = useCallback(
    (
      request: UpdateApprovalRequest,
      onSuccess?: (updatedAR: ApprovalRequestBase) => void,
      onError?: (error: APIException) => void
    ) =>
      updateApprovalRequestHook(request, {
        onSuccess: onSuccess,
        onError: (error) => {
          onError?.(error as APIException);
        }
      }),
    [updateApprovalRequestHook]
  );

  const deleteApprovalRequest = useCallback(
    (onError?: () => void) => {
      deleteApprovalRequestHook(approvalRequestId!, {
        onSuccess: () => {
          navigate(PATH.MY_REQUESTS, { replace: true });
        },
        onError: onError
      });
    },
    [deleteApprovalRequestHook, approvalRequestId, navigate]
  );

  const updateAllTrackingComments = (
    updatedTabs: Record<string, DisciplineTrackingComment[]>,
    isDirty: boolean = true
  ) => {
    setAllTrackingComments((old) => ({
      ...old,
      ...updatedTabs
    }));

    const updatedDirtyStates: Record<string, boolean> = {};
    Object.keys(updatedTabs).forEach((tab) => (updatedDirtyStates[tab] = isDirty));

    setDirtyTabs((old) => ({
      ...old,
      ...updatedDirtyStates
    }));
  };

  const updateAllAuthorisationComments = (
    updatedTabs: Record<string, DisciplineAuthorisationComment[]>,
    isDirty: boolean = true
  ) => {
    setAllAuthorisationComments((old) => ({
      ...old,
      ...updatedTabs
    }));

    const updatedDirtyStates: Record<string, boolean> = {};
    Object.keys(updatedTabs).forEach((tab) => (updatedDirtyStates[tab] = isDirty));

    setDirtyTabs((old) => ({
      ...old,
      ...updatedDirtyStates
    }));
  };

  const resetDisciplineTab = (approvalRequestDisciplineId: string) => {
    setAllTrackingComments((old) => {
      delete old[approvalRequestDisciplineId];
      return { ...old };
    });
    setAllAuthorisationComments((old) => {
      delete old[approvalRequestDisciplineId];
      return { ...old };
    });
    setDirtyTabs((old) => {
      delete old[approvalRequestDisciplineId];
      return { ...old };
    });
    setAllTrackingCommentErrors((old) => {
      delete old[approvalRequestDisciplineId];
      return { ...old };
    });
  };

  const { mutate: updateDisciplineComments } = useUpdateDisciplineComments(
    approvalRequestId!,
    isAuthorised,
    () => {
      const updatedTrackingTabs: Record<string, DisciplineTrackingComment[]> = {};
      const updatedAuthorisationTabs: Record<string, DisciplineAuthorisationComment[]> = {};

      updatedTrackingTabs[approvalRequestDisciplineId!] = allTrackingComments[approvalRequestDisciplineId!];
      updatedAuthorisationTabs[approvalRequestDisciplineId!] = allAuthorisationComments[approvalRequestDisciplineId!];

      updateAllTrackingComments(updatedTrackingTabs, false);
      updateAllAuthorisationComments(updatedAuthorisationTabs, false);

      if (isAuthorised) {
        authoriseDiscipline({
          approvalRequestDisciplineId,
          approvalRequestId: approvalRequestId
        });
      }

      setSnackBar({
        message: DISCIPLINE_MESSAGE.SAVE_SUCCESSFUL,
        open: true
      });
    },
    () => {
      appendAlertBanner(AR_UNEXPECTED_ERROR_BANNER);
    }
  );

  const { mutate: authoriseDiscipline } = useAuthoriseDiscipline(
    approvalRequestId!,
    () => {
      setIsAuthorised(false);
      setSnackBar({
        message: DISCIPLINE_MESSAGE.SAVE_SUCCESSFUL,
        open: true
      });
    },
    () => {
      appendAlertBanner(AR_UNEXPECTED_ERROR_BANNER);
    }
  );

  const authoriseDisciplineHandler = () => {
    setIsAuthorised(true);
    if (isTrackingCommentUpdatable || isAuthorisationCommentUpdatable) {
      saveDisciplineTabs();
    } else {
      authoriseDiscipline({
        approvalRequestDisciplineId,
        approvalRequestId: approvalRequestId
      });
    }
  };

  const saveDisciplineTabs = useCallback(
    (onSuccess?: () => void, onFail?: () => void) => {
      let disciplineTrackingComments: DisciplineTrackingComment[] = [];
      let disciplineAuthorisationComments: DisciplineAuthorisationComment[] = [];

      const tabsToUpdate: Record<string, DisciplineTrackingComment[]> = Object.entries(dirtyTabs).reduce(
        (commentsToUpdate: Record<string, DisciplineTrackingComment[]>, [approvalRequestDisciplineId, tabIsDirty]) => {
          if (tabIsDirty && ownedTabs.includes(approvalRequestDisciplineId)) {
            commentsToUpdate[approvalRequestDisciplineId] = allTrackingComments[approvalRequestDisciplineId];
            disciplineTrackingComments = commentsToUpdate[approvalRequestDisciplineId];
          }
          return commentsToUpdate;
        },
        {}
      );

      const authTabsToUpdate: Record<string, DisciplineAuthorisationComment[]> = Object.entries(dirtyTabs).reduce(
        (
          authCommentsToUpdate: Record<string, DisciplineAuthorisationComment[]>,
          [approvalRequestDisciplineId, tabIsDirty]
        ) => {
          if (tabIsDirty && ownedTabs.includes(approvalRequestDisciplineId)) {
            authCommentsToUpdate[approvalRequestDisciplineId] = allAuthorisationComments[approvalRequestDisciplineId];
          }
          return authCommentsToUpdate;
        },
        {}
      );

      disciplineAuthorisationComments = authTabsToUpdate[approvalRequestDisciplineId!] ?? [];

      const validateComments = () => {
        Object.entries(tabsToUpdate).forEach(([approvalRequestDisciplineId, trackingComments]) => {
          trackingCommentsSchema
            .validate(trackingComments, { abortEarly: false })
            .then(() => {
              setAllTrackingCommentErrors((existingErrors) => {
                const updatedErrors = { ...existingErrors };
                delete updatedErrors[approvalRequestDisciplineId];
                return updatedErrors;
              });
              updateDisciplineComments(
                {
                  approvalRequestDisciplineId,
                  disciplineTrackingComments,
                  disciplineAuthorisationComments
                },
                {
                  onSuccess
                }
              );
            })
            .catch(({ inner }: ValidationError) => {
              const tcErrors: DisciplineTrackingCommentError[] = inner.map((error) => ({
                trackingCommentId: error.params?.["trackingCommentId"] as string,
                expectedAuthorisationDate: error.path === "expectedAuthorisationDate" ? error.message : undefined,
                disciplineTrackingCommentDependencies:
                  error.path === "disciplineTrackingCommentDependencies" ? error.message : undefined
              }));
              setAllTrackingCommentErrors((existingErrors) => ({
                ...existingErrors,
                [approvalRequestDisciplineId]: tcErrors
              }));
              onFail?.();
              return;
            });
        });
      };

      if (isTrackingCommentUpdatable || isAuthorisationCommentUpdatable) {
        validateComments();
      }
    },
    [
      dirtyTabs,
      approvalRequestDisciplineId,
      isTrackingCommentUpdatable,
      isAuthorisationCommentUpdatable,
      ownedTabs,
      allTrackingComments,
      allAuthorisationComments,
      setAllTrackingCommentErrors,
      updateDisciplineComments
    ]
  );

  if (!approvalRequestId) {
    console.error("Unable to find `approvalRequestId` in URL path params.");
    navigate(PATH.MY_REQUESTS);
    return null;
  }

  if (
    [arFetched, arDisciplinesFetched, arTypesFetched, qaFetched, wcFetched, wscFetched, arWCFetched].some(
      (successful) => !successful
    )
  ) {
    return <Loading prefix="Approval Request" />;
  }

  // Values are asserted as not null, since the "fetched" checks above ensures they have something.
  const contextValue: ApprovalRequestContextType = {
    /* Data */
    approvalRequest: approvalRequest!,
    approvalRequestDisciplineId,
    approvalRequestDisciplines,
    approvalRequestId,
    approvalRequestType: approvalRequestTypes.find((arType) => arType.id === approvalRequest?.approvalRequestTypeId)!,
    fetchedARWorkCategoryList,
    questionsAnswers,
    workCategories,
    workSubCategories,
    arMap,
    mapFeatureLayer,
    mapFeatureSetByShapeId,

    /* Flags */
    arFetched,
    allQuestionsAnswered,
    isARDirty,
    qaFetched,
    uploadInProgress,
    userIsSMEOfAr,
    userIsSMEOfDiscipline,
    userIsSMEOfDisciplineOrAdmin,

    /* States */
    alertBanners,
    allAuthorisationComments,
    allTrackingCommentErrors,
    allTrackingComments,
    dirtyTabs,
    ownedTabs,
    loader,

    /* Methods */
    appendAlertBanner,
    authoriseDisciplineHandler,
    deleteApprovalRequest,
    removeAlertBanner,
    resetAlertBanner,
    resetDisciplineTab,
    saveApprovalRequest,
    saveDisciplineTabs,
    setAllQuestionsAnswered,
    updateAllAuthorisationComments,
    updateAllTrackingCommentErrors: setAllTrackingCommentErrors,
    updateAllTrackingComments,
    setUploadInProgress,
    updateLoader,
    resetLoader
  };

  return (
    <Suspense fallback={<Loading prefix="Approval Request" />}>
      <ApprovalRequestContext.Provider value={contextValue}>
        <FormProvider {...formMethods}>{children}</FormProvider>
      </ApprovalRequestContext.Provider>
    </Suspense>
  );
}
