import { Cycle } from "@/interfaces/ApprovalRequest";
import { ApprovalRequestReturnStatus, ReturnDraftPermitRecipient } from "@/interfaces/ApprovalRequestReturn";
import Geometry from "@arcgis/core/geometry/Geometry";
import Graphic from "@arcgis/core/Graphic";

export interface CreateNewARRequest {
  approvalRequestTypeId: string | undefined;
}

export interface ApprovalRequestQARequest {
  approvalRequestTypeId: string;
  approvalRequestId: string;
  isEnabled: boolean;
}

export interface ReturnApprovalRequest {
  approvalRequestId: string;
  returnReasonIds: string[];
  comments: string;
}

export interface UpdateApprovalRequestReturnRequest {
  approvalRequestId: string;
  decision: ApprovalRequestReturnStatus;
  commentaryId?: string;
  notificationId?: string;
}

export interface ReturnDraftPermitRequest {
  approvalRequestId: string;
  returnRecipient: ReturnDraftPermitRecipient;
  unauthorisedDisciplines: string[];
  comments: string;
}

export interface AmendPermitRequest {
  approvalRequestId: string;
  cycle: Cycle;
  extendBy?: number;
}

export interface AddApprovalRequestMap {
  approvalRequestId: string;
  fileData: FormData;
}

export enum SensitivityType {
  Intersecting,
  NonIntersecting
}

export interface SensitivityRule {
  active: boolean;
  name: string;
  layer_urls: string[];
  query: string;
  buffer: number;
  display_fields: string[];
  sensitivityType: SensitivityType;
  getLabel: (ruleName: string, attributes: Graphic["attributes"]) => string;
}

export interface SensitivityResult {
  name: string;
  sensitivityResults: SensitivityResultGraphics[];
  subjectGeometry: Geometry;
  getLabel: SensitivityRule["getLabel"];
  sensitivityType: SensitivityType;
}

export interface SensitivityResultGraphics {
  graphic: Graphic;
  sensitivityResultGeometry: Geometry;
  intersectingGeometry?: Geometry;
}
