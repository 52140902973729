import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Geometry from "@arcgis/core/geometry/Geometry";
import Polygon from "@arcgis/core/geometry/Polygon";
import Graphic from "@arcgis/core/Graphic";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import MapView from "@arcgis/core/views/MapView";
import { intersect, union, buffer, difference, contains } from "@arcgis/core/geometry/geometryEngine.js";
import { Symbol as EsriSymbol } from "@arcgis/core/symbols";
import { find } from "lodash";

import {
  GeometryDto,
  LayerBuffer,
  SensitivityResult,
  SensitivityResultGraphics,
  SensitivityRule,
  SensitivityType
} from "@/interfaces";

export function drawShapes(graphicsLayer: GraphicsLayer, geometries: Geometry | Geometry[], symbol?: EsriSymbol) {
  if (Array.isArray(geometries)) {
    geometries.forEach((geometry) => drawShapes(graphicsLayer, geometry, symbol));
    return;
  }

  graphicsLayer.add(
    new Graphic({
      geometry: geometries,
      symbol
    })
  );
}

export function getGeometryOverlap(
  baseGeometries: Geometry[],
  intersectingGeometries: Graphic[],
  layerBuffers: LayerBuffer[]
) {
  const results: Geometry[] = [];
  const convertedBaseGeometry = union(baseGeometries);

  const addIntersectionResult = (intersectionResults: Geometry | Geometry[]) => {
    if (Array.isArray(intersectionResults)) {
      intersectionResults.forEach((intersection) => results.push(intersection));
      return;
    }
    results.push(intersectionResults);
  };

  intersectingGeometries.forEach((intersectingGeometry) => {
    const layerBuffer = find(layerBuffers, { name: intersectingGeometry.attributes?.name });

    if (layerBuffer && layerBuffer.buffer && layerBuffer.buffer > 0) {
      const bufferedBaseGeometry = buffer(convertedBaseGeometry, layerBuffer.buffer);
      addIntersectionResult(intersect(intersectingGeometry.geometry, bufferedBaseGeometry as Geometry));
    } else {
      addIntersectionResult(intersect(intersectingGeometry.geometry, convertedBaseGeometry));
    }
  });

  return results;
}

export function concatGraphics(geometries: GeometryDto[] | Geometry[]) {
  return geometries.map((geometry) => Graphic.fromJSON(geometry));
}

export function getLayerBuffers(geometries: GeometryDto[]): LayerBuffer[] {
  const layerHasBuffer = (layerBuffer: LayerBuffer) => {
    return layerBuffer.name && layerBuffer.buffer && layerBuffer.buffer > 0;
  };
  return geometries
    .map((shapeGeometry) => {
      return {
        name: shapeGeometry.attributes?.name,
        buffer: shapeGeometry.buffer
      };
    })
    .filter(layerHasBuffer);
}

export function refreshMap(mapView: MapView) {
  mapView.extent = mapView.extent;
}

// execute a sensitivity query based on a rule and AR geometry
export function executeSensitivityQuery(rule: SensitivityRule, queryGeometry: Geometry): Promise<SensitivityResult> {
  // hand back a Promise so that multiple queries can be executed in parallel
  return new Promise<SensitivityResult>(async (resolve) => {
    // if the rule is not active, we can skip it
    if (!rule.active) {
      const emptyResult: SensitivityResult = {
        name: rule.name,
        sensitivityResults: [],
        subjectGeometry: queryGeometry,
        getLabel: rule.getLabel,
        sensitivityType: rule.sensitivityType
      };
      resolve(emptyResult);
    }

    let subjectGeometry: Geometry = queryGeometry;

    const featureLayers = rule.layer_urls.map((url) => new FeatureLayer({ url }));

    Promise.all(
      featureLayers.map((layer) => {
        const layerQuery = layer.createQuery();

        layerQuery.spatialRelationship = "intersects";
        layerQuery.geometry = queryGeometry;
        layerQuery.where = rule.query;

        if (rule.buffer !== 0) {
          layerQuery.distance = rule.buffer;
          layerQuery.units = "meters";
          subjectGeometry = buffer(queryGeometry, rule.buffer) as Geometry;
        }

        // execute the query and compose the results
        return layer.queryFeatures(layerQuery).then((response) => response.features);
      })
    )
      .then((features) => features.flat())
      .then((features) => {
        const result: SensitivityResult = {
          name: rule.name,
          sensitivityResults: [],
          subjectGeometry,
          getLabel: rule.getLabel,
          sensitivityType: rule.sensitivityType
        };

        switch (rule.sensitivityType) {
          case SensitivityType.Intersecting:
            result.sensitivityResults = getIntersectionSensitivity(features, subjectGeometry);
            break;
          case SensitivityType.NonIntersecting:
            result.sensitivityResults = getNonIntersectionSensitivity(features, subjectGeometry);
            break;
        }

        resolve(result);
      });
  });
}

function getIntersectionSensitivity(features: Graphic[], subjectGeometry: Geometry) {
  const sensitivityResults: SensitivityResultGraphics[] = [];

  features.forEach((feature) => {
    const intersection = intersect(feature.geometry, subjectGeometry);
    const graphic = new Graphic({ geometry: feature.geometry, attributes: feature.attributes });

    sensitivityResults.push({
      graphic,
      sensitivityResultGeometry: feature.geometry,
      intersectingGeometry: Array.isArray(intersection) ? union(intersection) : intersection
    });
  });

  return sensitivityResults;
}

function getNonIntersectionSensitivity(features: Graphic[], subjectGeometry: Geometry) {
  const sensitivityResults: SensitivityResultGraphics[] = [];
  const featuresGeometry: Geometry[] = [];

  features.forEach((feature) => featuresGeometry.push(feature.geometry));

  if (featuresGeometry.length === 0) {
    const graphic = new Graphic();
    sensitivityResults.push({ graphic, sensitivityResultGeometry: subjectGeometry });
    return sensitivityResults;
  }

  const combinedFeatureGeometry = union(featuresGeometry);
  const combinedSubjectAndFeatureGeometry = union([...featuresGeometry, subjectGeometry]);
  const differenceGeometry = difference(combinedSubjectAndFeatureGeometry, combinedFeatureGeometry);

  if (!differenceGeometry) {
    return sensitivityResults;
  }

  const nonIntersectingGeometries = splitMultiPolygon(differenceGeometry);

  nonIntersectingGeometries.forEach((geometry) => {
    const graphic = new Graphic({
      geometry
    });

    sensitivityResults.push({ graphic, sensitivityResultGeometry: geometry });
  });

  return sensitivityResults;
}

/**
 * Function that takes in geometry and split it into separate entities if it is a multi polygon.
 */
function splitMultiPolygon(geometry: Geometry | Geometry[]): Geometry[] {
  const polygon = (Array.isArray(geometry) ? union(geometry) : geometry) as Polygon;
  const externalPolygons: Polygon[] = [];
  const holes: number[][][] = [];

  // get all the "outer" rings of the polygon
  for (const ring of polygon.rings) {
    const clonedRing: number[][] = Object.assign([], ring);

    // clockwise rings are shapes, but anti-clockwise rings are "holes"
    if (polygon.isClockwise(ring)) {
      const newPolygon = new Polygon({
        hasZ: polygon.hasZ,
        hasM: polygon.hasM,
        rings: [clonedRing],
        spatialReference: polygon.spatialReference
      });

      externalPolygons.push(newPolygon);
    } else {
      // the ring is a "hole" grab it for post-processing
      holes.push(clonedRing);
    }
  }

  // check "hole" rings and assign them to the correct "outer" ring
  for (const hole of holes) {
    // cast the hole to a polygon
    const holePolygon = new Polygon({
      hasZ: polygon.hasZ,
      hasM: polygon.hasM,
      rings: [hole],
      spatialReference: polygon.spatialReference
    });

    // find the shape that "contains" the hole, and add the hole to the rings collection
    for (const externalPolygon of externalPolygons) {
      if (contains(externalPolygon, holePolygon)) {
        externalPolygon.rings.push(hole);
        break;
      }
    }
  }

  return externalPolygons;
}
