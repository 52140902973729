import { useEffect, useMemo, useState } from "react";
import { Box, Button, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { DataGridPro, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { useForm } from "react-hook-form";
import { ValidationError } from "yup";
import { LoadingButton } from "@mui/lab";
import { snackBarAtom } from "@/stores";
import { useSetAtom } from "jotai";

import { ADMIN_FORM, BUTTON_LABELS, DEFAULT_PAGE_SIZES } from "@/constants";
import {
  AddDisciplineUserMatrix,
  DisciplineUserMatrixResponse,
  IOption,
  LAMSRole,
  UpdateDisciplineUserMatrix
} from "@/interfaces";
import {
  useAddDisciplineUserMatrix,
  useAvailableMatrixDisciplines,
  useBusinessUnitList,
  useDisciplineUserMatrix,
  useGraphUsers,
  usePagination,
  useSitesByHubId,
  useGetDisciplineUserMatrixById,
  useUpdateDisciplineUserMatrix
} from "@/hooks";
import { addDisciplineUserMatrixSchema } from "@/validations";
import { AppFormSelect, AppFormAutoComplete } from "@/components/fields";
import { Dialog } from "@/components/dialogs";
import { UserHubMatrixConfigurationProps } from "./UserHubMatrixConfiguration";

interface AddDisciplineUserMatrixFormValues {
  id: string;
  hubId: string;
  siteId: string;
  businessUnitId: string;
  disciplineId: string;
  primaryApproverId: string;
  secondaryApproverId: string;
}

const ALL_OPTION: IOption = { id: "all", value: "All" };

const columns: GridColDef[] = [
  {
    field: "siteName",
    headerName: "Site",
    flex: 1
  },
  {
    field: "businessUnitName",
    headerName: "Business Area",
    flex: 1
  },
  {
    field: "disciplineName",
    headerName: "Discipline",
    flex: 1
  },
  {
    field: "primaryApproverName",
    headerName: "Primary",
    flex: 1
  },
  {
    field: "secondaryApproverName",
    headerName: "Secondary",
    flex: 1
  }
];

function substituteAllForNull(value: string) {
  return value.toLowerCase() === "all" || value === null ? null : value;
}

export function SMEConfiguration({ hubId }: UserHubMatrixConfigurationProps) {
  const { currentPage, pageSize, paginationModelChangeHandler, resetPagination } = usePagination();

  const setSnackBar = useSetAtom(snackBarAtom);
  const [userMatrixDialogOpen, setUserMatrixDialogOpen] = useState(false);
  const [editIsActive, setEditIsActive] = useState(false);
  const [isDisciplineReset, setIsDisciplineReset] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState<string | undefined>(undefined);

  const { data: allGraphUsers, isFetching: graphUsersLoading } = useGraphUsers([LAMSRole.TabOwner]);

  const { data: allDisciplineUsers, isLoading: isDisciplineUsersLoading } = useDisciplineUserMatrix({
    hubId: hubId,
    pageNumber: currentPage + 1,
    pageSize
  });

  const {
    control,
    getValues,
    setError,
    reset,
    resetField,
    watch,
    formState: { errors }
  } = useForm<AddDisciplineUserMatrixFormValues>({
    defaultValues: {
      siteId: "all",
      businessUnitId: "all"
    }
  });

  const siteId = watch("siteId");
  const businessUnitId = watch("businessUnitId");
  const primarySME = watch("primaryApproverId");
  const secondarySME = watch("secondaryApproverId");

  const { data: selectedUserMatrix, isSuccess: detailsSuccess } = useGetDisciplineUserMatrixById(selectedRowId);

  const { data: siteList } = useSitesByHubId(hubId);

  const { data: businessUnitList } = useBusinessUnitList(true);

  const { data: disciplineList } = useAvailableMatrixDisciplines(hubId, siteId, businessUnitId);

  const { mutate: addDisciplineUserMatrix, isLoading: addInProgress } = useAddDisciplineUserMatrix(
    { pageNumber: currentPage + 1, pageSize },
    () => {
      setUserMatrixDialogOpen(false);
      setSnackBar({
        message: ADMIN_FORM.ADD_DISCIPLINE_USER_MATRIX_SUCCESSFUL,
        open: true
      });
    }
  );

  const { mutate: updateDisciplineUserMatrix, isLoading: updateInProgress } = useUpdateDisciplineUserMatrix(
    { pageNumber: currentPage + 1, pageSize },
    () => {
      setUserMatrixDialogOpen(false);
      setSnackBar({
        message: ADMIN_FORM.UPDATE_DISCIPLINE_USER_MATRIX_SUCCESSFUL,
        open: true
      });
    }
  );

  const smeOptions: IOption[] = useMemo(() => {
    return allGraphUsers.reduce((optionList: IOption[], option) => {
      if (![primarySME, secondarySME].includes(option.id)) {
        optionList.push({ id: option.id, value: option.displayName });
      }

      return optionList;
    }, []);
  }, [allGraphUsers, primarySME, secondarySME]);

  const validateAndSaveSME = () => {
    const formValues = getValues();

    if (editIsActive) {
      const request: UpdateDisciplineUserMatrix = {
        id: selectedRowId!,
        hubId: hubId,
        siteId: substituteAllForNull(formValues.siteId!),
        businessUnitId: substituteAllForNull(formValues.businessUnitId!),
        disciplineId: formValues.disciplineId!,
        primaryApproverId: formValues.primaryApproverId,
        primaryApproverName: allGraphUsers.find((g) => g.id === formValues.primaryApproverId)?.displayName,
        secondaryApproverId: formValues.secondaryApproverId,
        secondaryApproverName: allGraphUsers.find((g) => g.id === formValues.secondaryApproverId)?.displayName
      };

      addDisciplineUserMatrixSchema
        .validate({ ...formValues, hubId: hubId }, { abortEarly: false })
        .then(() => updateDisciplineUserMatrix(request))
        .catch(({ inner }: ValidationError) => {
          inner.forEach(({ path, message }) => {
            setError(path as keyof AddDisciplineUserMatrixFormValues, { message });
          });
        });
    } else {
      const request: AddDisciplineUserMatrix = {
        hubId: hubId,
        siteId: substituteAllForNull(formValues.siteId!),
        businessUnitId: substituteAllForNull(formValues.businessUnitId!),
        disciplineId: formValues.disciplineId!,
        primaryApproverId: formValues.primaryApproverId,
        primaryApproverName: allGraphUsers.find((g) => g.id === formValues.primaryApproverId)?.displayName,
        secondaryApproverId: formValues.secondaryApproverId,
        secondaryApproverName: allGraphUsers.find((g) => g.id === formValues.secondaryApproverId)?.displayName
      };

      addDisciplineUserMatrixSchema
        .validate({ ...formValues, hubId: hubId }, { abortEarly: false })
        .then(() => addDisciplineUserMatrix(request))
        .catch(({ inner }: ValidationError) => {
          inner.forEach(({ path, message }) => {
            setError(path as keyof AddDisciplineUserMatrixFormValues, { message });
          });
        });
    }
  };

  const handleEditOnclick = () => {
    if (detailsSuccess) {
      reset({
        id: selectedUserMatrix.id,
        hubId: selectedUserMatrix.hubId,
        siteId: selectedUserMatrix.siteId ?? ALL_OPTION.id,
        businessUnitId: selectedUserMatrix.businessUnitId ?? ALL_OPTION.id,
        disciplineId: selectedUserMatrix.disciplineId,
        primaryApproverId: selectedUserMatrix.primaryApproverId,
        secondaryApproverId: selectedUserMatrix.secondaryApproverId
      });
    }
    setUserMatrixDialogOpen(true);
  };

  useEffect(resetPagination, [hubId]);

  function GridActionButtons() {
    return (
      <Box>
        <LoadingButton
          data-testid="sme-config-button-add"
          variant="text"
          onClick={() => {
            setUserMatrixDialogOpen(true);
            reset({});
          }}
          disabled={!hubId || editIsActive}
          loading={addInProgress || updateInProgress}
        >
          Add
        </LoadingButton>
        <Button
          data-testid="sme-config-button-edit"
          variant="text"
          onClick={() => {
            handleEditOnclick();
          }}
          disabled={!editIsActive}
        >
          Edit
        </Button>
        <Button
          data-testid="sme-config-button-delete"
          variant="text"
          onClick={() => {
            alert("I am Delete Button");
          }}
          disabled
        >
          Delete
        </Button>
      </Box>
    );
  }

  function CustomNoRowsOverlay() {
    return (
      <Typography
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%"
        }}
      >
        No rows
      </Typography>
    );
  }

  return (
    <Card sx={{ padding: "0.5rem", overflow: "auto" }}>
      <CardHeader title="Discipline SMEs" action={<GridActionButtons />} />
      <CardContent>
        <DataGridPro
          autoHeight={true}
          data-testid={`discipline-user-matrix-table`}
          columns={columns}
          rows={allDisciplineUsers.items}
          getRowId={(row: DisciplineUserMatrixResponse) => row.id}
          loading={isDisciplineUsersLoading}
          paginationMode="server"
          pagination={true}
          paginationModel={{
            pageSize: allDisciplineUsers.pageSize,
            page: allDisciplineUsers.pageNumber - 1
          }}
          onPaginationModelChange={paginationModelChangeHandler}
          rowCount={allDisciplineUsers.totalRecords}
          pageSizeOptions={DEFAULT_PAGE_SIZES}
          slots={{
            noResultsOverlay: CustomNoRowsOverlay,
            noRowsOverlay: CustomNoRowsOverlay
          }}
          disableColumnMenu={true}
          checkboxSelection
          onRowSelectionModelChange={(ids: GridRowSelectionModel) => {
            if (ids.length > 0) {
              setSelectedRowId(ids[0].toString());
            }
            if (ids.length === 1) {
              setEditIsActive(true);
            } else {
              setEditIsActive(false);
            }
          }}
        />
      </CardContent>
      <Dialog
        data-testid="sme-user-matrix-dialog"
        open={userMatrixDialogOpen}
        onClose={() => {
          setUserMatrixDialogOpen(false);
          setIsDisciplineReset(false);
          reset({});
        }}
        title={editIsActive ? "Edit Discipline SME" : "Add Discipline SME"}
        actions={[
          {
            label: BUTTON_LABELS.CANCEL,
            onClick: () => {
              setUserMatrixDialogOpen(false);
              setIsDisciplineReset(false);
              reset({});
            }
          },
          {
            label: editIsActive ? BUTTON_LABELS.APPLY : BUTTON_LABELS.ADD,
            disabled:
              watch("disciplineId") === undefined ||
              watch("disciplineId") === "" ||
              watch("primaryApproverId") === undefined,
            onClick: () => {
              validateAndSaveSME();
              setUserMatrixDialogOpen(false);
              setIsDisciplineReset(false);
              reset({});
            }
          }
        ]}
      >
        <Stack rowGap={0.5} paddingTop={1} width="26.5rem">
          <AppFormSelect
            data-testid="sme-matrix-modal-site-field"
            control={control}
            initialValue={ALL_OPTION.id}
            label={"Site"}
            name={"siteId"}
            options={[ALL_OPTION, ...siteList]}
            error={errors.siteId}
            size="small"
            onChangeHandler={() => {
              resetField("disciplineId", {
                keepDirty: true,
                keepTouched: true,
                defaultValue: ""
              });
              setIsDisciplineReset(true);
            }}
          />
          <AppFormSelect
            data-testid="sme-matrix-modal-business-unit-field"
            control={control}
            initialValue={ALL_OPTION.id}
            label={"Business Unit"}
            name={"businessUnitId"}
            options={[ALL_OPTION, ...businessUnitList]}
            error={errors.businessUnitId}
            size="small"
            onChangeHandler={() => {
              resetField("disciplineId", {
                keepDirty: true,
                keepTouched: true,
                defaultValue: ""
              });
              setIsDisciplineReset(true);
            }}
          />
          <AppFormSelect
            data-testid="sme-matrix-modal-discipline-field"
            control={control}
            label={"Discipline"}
            name={"disciplineId"}
            options={
              editIsActive && !isDisciplineReset
                ? [
                    { id: selectedUserMatrix?.disciplineId, value: selectedUserMatrix?.disciplineName },
                    ...disciplineList
                  ]
                : disciplineList
            }
            error={errors.disciplineId}
            size="small"
          />
          <AppFormAutoComplete
            data-testid="sme-matrix-modal-primary-sme-field"
            id="primary-sme-dropdown"
            control={control}
            name="primaryApproverId"
            label={"Primary SME"}
            loading={graphUsersLoading}
            options={smeOptions}
            error={errors.primaryApproverId}
            size="small"
            isRequired={true}
            autoSelect={true}
            defaultValue={
              editIsActive
                ? {
                    id: selectedUserMatrix?.primaryApproverId,
                    value: selectedUserMatrix?.primaryApproverName
                  }
                : undefined
            }
          />
          <AppFormAutoComplete
            data-testid="sme-matrix-modal-secondary-sme-field"
            id="secondary-sme-dropdown"
            control={control}
            name="secondaryApproverId"
            label={"Secondary SME"}
            loading={graphUsersLoading}
            options={smeOptions}
            error={errors.secondaryApproverId}
            size="small"
            isRequired={false}
            autoSelect={true}
            defaultValue={
              editIsActive
                ? {
                    id: selectedUserMatrix?.secondaryApproverId ? selectedUserMatrix?.secondaryApproverId : "",
                    value: selectedUserMatrix?.secondaryApproverName ? selectedUserMatrix?.secondaryApproverName : ""
                  }
                : { id: "", value: "" }
            }
          />
        </Stack>
      </Dialog>
    </Card>
  );
}
