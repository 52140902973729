import { useMemo, useState } from "react";
import { Skeleton, Tab, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DataGridPro, GridColDef, GridRenderCellParams, GridSortModel } from "@mui/x-data-grid-pro";
import { generatePath, useNavigate } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import { ApprovalRequestDisciplineStatus, ApprovalRequestStatus, Cycle } from "@/interfaces";
import {
  DEFAULT_PAGE_SIZES,
  DISCIPLINE_STATUSES,
  APPROVAL_REQUEST_PATH,
  DEFAULT_SME_DASHBOARD_FILTER_QUERY
} from "@/constants";
import { flattenPaginatedARList, formatDate, toKebabCase } from "@/utils";
import { useAssignedARCounts, usePagination, useUnreadIndicator, useSMEAssignedARsList } from "@/hooks";
import { DisciplineStatusIcon, UnreadBadge } from "@/components";
import { SMEDashboardFilterToolbar, SMEDashboardFilterQuery } from "@/components/toolbars";

const tabs = [
  {
    header: DISCIPLINE_STATUSES.NOT_STARTED,
    status: ApprovalRequestDisciplineStatus.NotStarted
  },
  {
    header: DISCIPLINE_STATUSES.WIP,
    status: ApprovalRequestDisciplineStatus.WIP
  }
];

const renderIcon = ({ value }: GridRenderCellParams) => {
  return <DisciplineStatusIcon status={value} />;
};

interface BadgedARCountProps {
  label: string;
  arCount: number;
  pendingNotifications?: number;
}

const BadgedARCount = ({ label, arCount, pendingNotifications = 0 }: BadgedARCountProps) => {
  return (
    <Box>
      <UnreadBadge sx={{ mx: "2rem" }} data-testid={toKebabCase(label)} visible={pendingNotifications > 0}>
        <Typography fontSize="3rem" sx={{ width: "3rem" }} data-testid={`${toKebabCase(label)}-badge-count`}>
          {arCount}
        </Typography>
      </UnreadBadge>
      <Typography data-testid={`${toKebabCase(label)}-badge-label`}>{label}</Typography>
    </Box>
  );
};

export const SMEDashboard = () => {
  const navigate = useNavigate();

  const { currentPage, pageSize, paginationModelChangeHandler } = usePagination();
  const [currentTab, setCurrentTab] = useState<string>(ApprovalRequestDisciplineStatus.NotStarted.toString());
  const disciplineStatusFilter = parseInt(currentTab, 10) as ApprovalRequestDisciplineStatus;
  const [smeDashboardFilterQuery, setSMEDashboardFilterQuery] = useState<SMEDashboardFilterQuery>(
    DEFAULT_SME_DASHBOARD_FILTER_QUERY
  );
  const [sortField, setSortField] = useState<string>();
  const approvalRequestStatusFilter = [ApprovalRequestStatus.Distributed];

  const { data: assignedARCounts, isFetching, isLoading } = useAssignedARCounts();

  const { data: assignedARs } = useSMEAssignedARsList({
    disciplineStatus: disciplineStatusFilter,
    approvalRequestStatus: approvalRequestStatusFilter,
    pageNumber: currentPage + 1,
    pageSize,
    sortField,
    hubId: smeDashboardFilterQuery.selectedHub,
    siteId: smeDashboardFilterQuery.selectedSite,
    approvalRequestTypeId: smeDashboardFilterQuery.selectedApprovalRequestType
  });

  const { isUnread } = useUnreadIndicator(assignedARs.items);

  const handleSortModelChange = (sortModel: GridSortModel) => {
    const sortClause = sortModel
      .map(({ field, sort }) => {
        return `${field} ${sort}`;
      })
      .join(",");

    setSortField(sortClause);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "",
      sortable: false,
      flex: 0.01,
      renderCell: ({ value }) => (
        <Box
          data-testid={`unread-indicator-${value}`}
          width="0.5rem"
          height="0.5rem"
          display="block"
          borderRadius="100%"
          sx={{ background: "red" }}
          visibility={isUnread(value) ? "visible" : "hidden"}
        />
      )
    },
    {
      field: "referenceNo",
      headerName: "AR Number",
      flex: 1
    },
    {
      field: "title",
      headerName: "Title",
      flex: 2
    },
    {
      field: "approvalRequestTypeName",
      headerName: "Type",
      flex: 1
    },
    {
      field: "top",
      headerName: "TOP",
      description: "TO Partnerships",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderIcon
    },
    {
      field: "biological",
      headerName: "B",
      description: "Biological",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderIcon
    },
    {
      field: "environmentalOps",
      headerName: "EO",
      description: "Environmental Ops",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderIcon
    },
    {
      field: "tenure",
      headerName: "T",
      description: "Tenure",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderIcon
    },
    {
      field: "stateAgreement",
      headerName: "SA",
      description: "State Agreement",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderIcon
    },
    {
      field: "miningAct",
      headerName: "M",
      description: "Mining Act",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderIcon
    },
    {
      field: "water",
      headerName: "W",
      description: "Water",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderIcon
    },
    {
      field: "environmentalApprovals",
      headerName: "EA",
      description: "Environmental Approvals",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderIcon
    },
    {
      field: "partV",
      headerName: "V",
      description: "Part V",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderIcon
    },
    {
      field: "nvcp",
      headerName: "NVCP",
      description: "Native Vegetation Clearing Permit",
      headerAlign: "center",
      sortable: false,
      flex: 0.3,
      renderCell: renderIcon
    },
    {
      field: "shire",
      headerName: "Sh",
      description: "Shire",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderIcon
    },
    {
      field: "hub",
      headerName: "Hub",
      flex: 1
    },
    {
      field: "cycle",
      headerName: "Cycle",
      sortable: false,
      valueFormatter: ({ value }) => Cycle[value],
      flex: 1
    },
    {
      field: "submittedDate",
      headerName: "Submitted",
      flex: 1,
      valueFormatter: ({ value }) => formatDate(value)
    },
    {
      field: "requiredByDate",
      headerName: "Required By",
      flex: 1,
      valueFormatter: ({ value }) => formatDate(value)
    }
  ];

  const flattenedARs = useMemo(() => flattenPaginatedARList(assignedARs), [assignedARs]);

  return (
    <Box>
      <TabContext value={currentTab.toString()}>
        <TabList
          onChange={(_, value) => {
            setCurrentTab(value);
            setSMEDashboardFilterQuery(DEFAULT_SME_DASHBOARD_FILTER_QUERY);
          }}
          sx={{ borderBottom: "1px solid #e0e0e0" }}
        >
          <Tab
            data-testid={`sme-dashboard-${toKebabCase(DISCIPLINE_STATUSES.NOT_STARTED)}-tab`}
            label={
              isFetching ? (
                <Skeleton variant="rectangular" width={"3rem"} height={50} />
              ) : (
                <BadgedARCount
                  label={DISCIPLINE_STATUSES.NOT_STARTED}
                  pendingNotifications={assignedARCounts.notStarted.notificationCount}
                  arCount={assignedARCounts.notStarted.approvalRequestCount}
                />
              )
            }
            value={ApprovalRequestDisciplineStatus.NotStarted.toString()}
          />
          <Tab
            data-testid={`sme-dashboard-${toKebabCase(DISCIPLINE_STATUSES.WIP)}-tab`}
            label={
              isFetching ? (
                <Skeleton variant="rectangular" width={"3rem"} height={50} />
              ) : (
                <BadgedARCount
                  label={DISCIPLINE_STATUSES.WIP}
                  pendingNotifications={assignedARCounts.wip.notificationCount}
                  arCount={assignedARCounts.wip.approvalRequestCount}
                />
              )
            }
            value={ApprovalRequestDisciplineStatus.WIP.toString()}
          />
        </TabList>
        {tabs.map(({ header, status }, index) => (
          <TabPanel key={`${status}-${index}`} value={status.toString()}>
            <DataGridPro
              data-testid={`sme-dashboard-${toKebabCase(header)}-table`}
              columns={columns}
              rows={flattenedARs}
              getRowId={(row) => row.id}
              loading={isLoading}
              paginationMode="server"
              pagination={true}
              paginationModel={{
                pageSize: assignedARs.pageSize,
                page: assignedARs.pageNumber - 1
              }}
              onPaginationModelChange={paginationModelChangeHandler}
              rowCount={assignedARs.totalRecords}
              pageSizeOptions={DEFAULT_PAGE_SIZES}
              onRowClick={({ row: { id } }) => {
                navigate(
                  generatePath(APPROVAL_REQUEST_PATH.DETAILS, {
                    approvalRequestId: id
                  })
                );
              }}
              onSortModelChange={handleSortModelChange}
              disableColumnMenu={true}
              slots={{ toolbar: SMEDashboardFilterToolbar }}
              slotProps={{
                toolbar: {
                  onFilterChange: (filterQuery: SMEDashboardFilterQuery) => {
                    setSMEDashboardFilterQuery(filterQuery);
                  },
                  approvalRequestStatusFilter,
                  disciplineStatusFilter
                }
              }}
              sx={{
                border: "none"
              }}
            />
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};
